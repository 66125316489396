import "./Components/Home/wdyr.js";

import { useMediaQuery } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";
import { createMemoryHistory } from "history";
import { observer } from "mobx-react-lite";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import "normalize.css/normalize.css";
import React, { Suspense, useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
// import { QueryClient } from "react-query";
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, Navigate } from 'react-router-dom';
import WebFont from "webfontloader";
import About from "./Components/Home/About.js";
// import CreateScreen from "./Components/Home/CreateScreen.js";
import { faEnvelope, faTriangle } from "@fortawesome/pro-solid-svg-icons";

import { AuthContext, AuthProvider } from ".//Components/Home/AuthContext";
import Header from "./Components/Home/Header.js";
// import LoadingSpinner from "./Components/Home/LoadingSpinner"; // You'll need to create this
import Main from "./Components/Home/Main.js";
// import ReactVirtualPage from "./Components/Home/ReactVirtualPage.js";
import ProgressBar from "./Components/Home/ProgressBar.js";
import Room from "./Components/Home/Room/Room.js";
import "./Components/styles/stylesheet.css";
import FullRoute from "./routers/FullRoute";
import PrivateRoute from "./routers/PrivateRoute";
import PublicRoute from "./routers/PublicRoute";
import { AuthenticationStoreContext } from "./store/AuthenticationStore.js";
import { FlowStoreContext } from "./store/FlowStore.js";
import { WidgetStoreContext } from "./store/WidgetStore.js";
import { EditorStoreContext} from "./store/EditorStore.js"
import ReactModal from "react-modal";
import LoginM from "./Components/Home/LoginM.js"
import SigninM from "./Components/Home/SigninM.js";
import FlowNotFound from "./Components/Home/FlowNotFound.js"
import Tooltip from "./Components/Home/Tooltip/Tooltip.js"
import Admin from "./Components/Home/Admin.js"
import firebase from "firebase";
import CommentsModal from "./Components/Home/CommentsModal.js";
import { CommentsStoreContext } from "./store/CommentsStore.js";
// import RemoteComponent from 'LandingPageApp/App';
import SetAdvancedModal from "./Components/Home/Editor/EditorModals/set-advanced-modal.js";
import Auth from "./Components/Home/Auth.js";
import { NotificationProvider } from '../src/Components/Home/NotificationContext.js'; // Adjust the import path as necessary
import Help from "./Components/Home/Help.js";
import { useNavigate } from "react-router-dom";

const MemoizedHeader = React.memo(Header);
// const LandingPage = React.lazy(() => import('./landing/App'));



const LoadingSpinner = ()=> { 
  return(<div></div>)
}

const RemoteComponent = () => {
  return (
    <iframe 
      src="https://d27jilz6py25oz.cloudfront.net/" 
      style={{height: '100vh', width: '100vw', border: 'none'}} 
    />
  );
};

// import CreateScreen from "./Components/Home/CreateScreen.js";
// import CreateScreen from "./Components/Home/CreateScreen.js";
// import CreateScreen from "./Components/Home/CreateScreen.js";

// import CreateScreen from "./Components/Home/CreateScreen.js";
let userAgent = navigator.userAgent;

let browserName;
if (userAgent.match(/chrome|chromium|crios/i)) {
  browserName = "chrome";
} else if (userAgent.match(/firefox|fxios/i)) {
  browserName = "firefox";
} else if (userAgent.match(/safari/i)) {
  browserName = "safari";
} else if (userAgent.match(/opr\//i)) {
  browserName = "opera";
} else if (userAgent.match(/edg/i)) {
  browserName = "edge";
} else {
  browserName = "No browser detection";
}
let flowArr = [];
localStorage.setItem("flows", JSON.stringify(flowArr));

// const PrivateRoute = React.lazy(() => import("./routers/PrivateRoute"));

const CreateScreen = React.lazy(() =>
  import("./Components/Home/CreateMenuPagination.js")
);

const MakeScreenShot = React.lazy(() =>
  import("./Components/Home/MakeScreenShot.js")
);
const Notifications = React.lazy(() =>
  import("./Components/Home/NotificationRoute.js")
);
const ProfilePage = React.lazy(() =>
  import("./Components/Home/ProfilePage.js")
);
// const Room = React.lazy(() => import("./Components/Home/Room/Room.js"));

// const StandaloneApp = React.lazy(() =>
//   import("./Components/Home/StandaloneApp.js")
// );

const CommentsMobile = React.lazy(() =>
  import("./Components/Home/RoomComponents/CommentsMobile.js")
);
const SearchScreen = React.lazy(() =>
  import("./Components/Home/SearchScreen.js")
);
// const Header = React.lazy(() => import("./Components/Home/Header.js"));
const SignUp = React.lazy(() => import("./Components/Home/SignUp.js"));
const VerifyEmail = React.lazy(() =>
  import("./Components/Home/verifyEmail.js")
);
// const Embed = React.lazy(() => import("./Components/Home/embed.js"));

const Full = React.lazy(() => import("./Components/Home/full.js"));

// const GifRecap = React.lazy(() => import("./Components/Home/GifRecap.js"));
// const GifRecapAI = React.lazy(() => import("./Components/Home/GifRecapAI.js"));

const Login = React.lazy(() => import("./Components/Home/Login.js"));
// const Main = React.lazy(() => import("./Components/Home/Main.js"));
// const Home = React.lazy(() => import("./Components/Home/Home.js"));
const Settings = React.lazy(() => import("./Components/Home/Settings.js"));
const Messages = React.lazy(() => import("./Components/Home/Messages.js"));
const CreateInvite = React.lazy(() => import("./Components/Home/CreateInvite.js"));
const TermsOfUse = React.lazy(()=> import("./Components/Home/Terms.js"));
const PrivacyPolicy = React.lazy(()=> import("./Components/Home/Privacy.js"));

const Maintenance = React.lazy(() =>
  import("./Components/Home/Maintenance.js")
);

const InviteVerification = React.lazy(() => import("./Components/Home/InviteScreen.js"));


const ProfileSetup = React.lazy(() => import("./Components/Home/ProfileSetup.js"));
const EmailVerification = React.lazy(() => import("./Components/Home/EmailVerification.js"));

const PasswordReset = React.lazy(() => import("./Components/Home/PasswordReset.js"));
const Record = React.lazy(() => import("./Components/Home/Record.js"));

// const ReactVirtualPage = React.lazy(() =>
//   import("./Components/Home/ReactVirtualPage.js")
// );

// const Dashboard = React.lazy(() => import("./Dashboard"));
// const Overview = React.lazy(() => import("./Overview"));
// const History = React.lazy(() => import("./History"));
// const NotFound = React.lazy(() => import("./NotFound"));

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

let seen = [];
WebFont.load({
  google: {
    families: ["Quicksand", "Quicksand"],
  },
});
const IS_STRIPE_LIVE = false;


let p = false;
let hasRenderedRoot = false;
let hasRenderedHeader = false;

const renderApp = (id, hasRendered) => {
  //if (hasRendered === false) {
  hasRendered = true;
  ReactDOM.render([jsx], document.getElementById(id));
  // }
};

const HomeDefault = observer(()=> {
  const [showModal, setShowModal] = useState(false);
  const AuthenticationStore = useContext(AuthenticationStoreContext)

  window.addEventListener('message', (event) => {
    // You can check event.origin here to verify that the message
    // is coming from a trusted source
    if(event.data == "authModal") {
      setShowModal(true);
      console.log('Message received from iframe:', event.data);
  
    }
  
  });
  return (
    <div style={{height:'100vh', width:'100vw'}}>
      <RemoteComponent /> 
      <ReactModal
        ariaHideApp={false}
        closeTimeoutMS={500}
        isOpen={showModal}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#3F3F3E",
            overflow: "hidden",
            borderRadius: "15px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% / 1.1)",
            maxWidth: 570,
                  // maxHeight: WidgetStore.currentModalHeight,
          },
        }}>
          <div
            onClick={() => {
              setShowModal(false);
            }}
            style={{
              position: "absolute",
              right: 0,
              margin: 10,
              cursor: "pointer",
              zIndex: 1,
            }}>
            <img src="/images/close-circle.svg"   style={{
                display: "block",
                position: "absolute",
                right: 10,
                top: 10,
              }}/>
          </div>

        <LoginM /> 
        </ReactModal>
          </div>
        );
  })

  const ProtectedRoomRoute = ({ component: Component }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [documentExists, setDocumentExists] = useState(false);
  
    useEffect(() => {
      const checkDocumentExistsWithDelay = async () => {
        const delay = new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the delay (in milliseconds) as needed.
        
        const docRef = firebase.firestore().collection('flows').doc(id);
        const doc = await docRef.get();
        
        // Wait for both the document check and the artificial delay
        await delay;
  
        setDocumentExists(doc.exists);
        setLoading(false);
      };
  
      checkDocumentExistsWithDelay();
    }, [id]);
  
    if (loading) {
      return (
        <div
          style={{
            height: "-webkit-fill-available",
            width: "100%",
            backgroundColor: "#323232",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: 'absolute',
            top:0,
            zIndex:100000
          }}
        >
          <img src="/remix-loading.svg" style={{ height: 55, marginBottom: 20 }} />
          <ProgressBar duration={10} maxDuration={1000} />
          <p style={{ fontFamily: 'quicksand', fontSize: 14, position: 'absolute', bottom: 30, color: 'white' }}>Loading...</p>
        </div>
      );
    }
  
    return documentExists || id === "new" ? <Component /> : <Navigate to="/flow-not-found" />;
  };

  
  const ProtectedPageRoute = ({ children }) => {
    const { currentUser } = useContext(AuthContext);
    const { username } = useParams(); // Assuming 'username' is a dynamic segment in the path
  
    const isLoggedIn = currentUser != null;
  
    // Normalize username if exists
    let normalizedUsername = '';
    if (username && username.startsWith('@')) {
      normalizedUsername = username.charAt(1).toLowerCase() + username.slice(2);
    }
  
    // Here, we assume children is a function that can accept props, you'll need to adjust your child components accordingly.
    return isLoggedIn ? (typeof children === 'function' ? children({ normalizedUsername }) : children) : <Navigate to="/not-found" replace />;
  };

  const ProtectedAdminRoute = ({ children }) => {
    const [userHasAccess, setUserHasAccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
  
    useEffect(() => {
      const checkAccess = async () => {
        const user = firebase.auth().currentUser;
        if (!user) {
          navigate('/login');
          return;
        }
  
        const userRef = firebase.firestore().collection('users').doc(user.uid);
        const doc = await userRef.get();
  
        if (doc.exists && doc.data().admin) {
          setUserHasAccess(true);
        } else {
          navigate('/not-found'); // Redirect to a 404 page or equivalent
        }
        setIsLoading(false);
      };
  
      checkAccess();
    }, [navigate]);
  
    if (isLoading) return <div>Loading...</div>;
    if (!userHasAccess) return null;
  
    return children;
  };




const AppRoutes = observer(() => {
  //console.log("[index.js] Routes");
  const [showAuth, setShowAuth] = useState(false);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const currentLocation = useLocation(); // Use the useLocation hook
  // Dependency array ensures this runs only when currentLocation changes
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = firebase.firestore()
        .collection('notifications')
        .where('receiver', '==', currentUser.displayName) // Adjust based on your needs
        .onSnapshot(snapshot => {
          // Here, determine if there are new notifications
          const newNotifications = snapshot.docs.filter(doc => {
            const notification = doc.data();
            // Add logic to determine if the notification is new, e.g., based on a 'read' flag or timestamp
            return !notification.read;
          });
          console.log('notification',newNotifications.length > 0);
        });

      return () => unsubscribe(); // Clean up the listener
    }
  }, [currentUser]);
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // mode: prefersDarkMode ? "dark" : "light",
          type: "dark",
          primary: { main: "#40FFE8", contrastText: "#000" },
        },
        overrides: {
          MuiAvatar: {
            img: {
              // handle correctly non-square images
              objectFit: "cover",
              width: "100%",
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  // let currentUserString = JSON.parse(
  //   localStorage.getItem("flowroom-user")
  // ).displayName;

  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const location = useLocation(); // This hook gives you access to the location object

  const history = createMemoryHistory();
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const localData = JSON.parse(localStorage.getItem("flowroom-user"));

  // // Check if user is logged in
  const isLoggedIn = currentUser != null;
  const CommentsStore = useContext(CommentsStoreContext);

  const [sendVerifyButton, setSendVerifyButton] = useState(false);
 const [emailVerification, setEmailVerification] = useState(false);
  // if(isLoggedIn && currentUser.emailVerified == false) {
  //   history.push('/verifyEmail')
  // }

  const SignUpHeader = ()=>{
    return(<div style={{height:50, backgroundColor:'#1c1c1c', display:'flex', alignItems:'center', justifyContent:'center'}}><div style={{    backgroundImage: 'url(/images/logo/logo.svg)',
    backgroundSize: 'contain',
    width: '147px',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    height: '32.1px',
    position: 'relative',
    left: '-1px'}}></div>
    
    <div onClick={() => {
                            firebase
                              .auth()
                              .signOut()
                              .then(function () {
                                // Sign-out successful.
                                localStorage.clear();
                                localStorage.setItem(
                                  "flowroom-user",
                                  JSON.stringify({
                                    isLoggedIn: false,
                                  })
                                );
                                AuthenticationStore.setIsLoggedIn(false);
  
                                window.location.replace("/");
                              })
                              .catch(function (error) {
                                // An error happened.
                              });
                          }} style={{position:'absolute', right:20, cursor:'pointer'}}><p style={{fontFamily:'quicksand', fontSize:14, color:'white'}}>Sign Out</p></div>
    
    </div>)
  }

  function MessagesWrapper() {
 
    let { id } = useParams(); // If you need to access params
  console.log('currentUser', currentUser);
  if(!isLoggedIn) {
    return
  }
    return <Messages currentUser={currentUser} id={id} displayName={currentUser.displayName} />;
  }
  const getUserProfileSetupStatus = async (userId) => {
    const docRef = firebase.firestore().collection('users').doc(userId);
    const doc = await docRef.get();
    if (doc.exists) {
      return doc.data().initialProfileSetup || false; // Return the status or default to false
    }
    return false; // Default to false if the document doesn't exist
  };
  
  const RequireEmailVerification = ({ children }) => {
    const { currentUser } = useContext(AuthContext);
    const [profileSetupCompleted, setProfileSetupCompleted] = useState(null);
    const [loading, setLoading] = useState(true);
    const isLoggedIn = currentUser != null;
    const isVerified = currentUser?.emailVerified || currentUser?.phoneNumber;
    const location = useLocation();
  
    useEffect(() => {
      if (isLoggedIn && currentUser?.uid) {
        getUserProfileSetupStatus(currentUser.uid).then(isSetupComplete => {
          setProfileSetupCompleted(isSetupComplete);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }, [isLoggedIn, currentUser?.uid]);
  
    if (loading) {
      return <div></div>;
    }
  
    const onAuthRoute = location.pathname.includes('/auth');
    const onEmailVerificationRoute = location.pathname.includes('/email-verification');
    const onVerifyEmailRoute = location.pathname.includes('/verifyEmail');
    const onProfileSetupRoute = location.pathname.includes('/ProfileSetup');
  
    // Allow navigation to /auth without redirection
    if (onAuthRoute) {
      return <>{children}</>;
    }
  
    // Do not redirect on the email verification route
    if (onEmailVerificationRoute) {
      return <>{children}</>;
    }
  
    if (!isLoggedIn && (onVerifyEmailRoute || onProfileSetupRoute)) {
      return <Navigate to="/not-found" replace />;
    } else if (isLoggedIn && isVerified && profileSetupCompleted === false && !onProfileSetupRoute) {
      return <Navigate to="/ProfileSetup" replace />;
    } else if (isLoggedIn && !isVerified && !onVerifyEmailRoute) {
      return <Navigate to="/verifyEmail" replace />;
    }
  
    return <>{children}</>;
  };

const RouteWrapper = React.memo(({ children }) => {
  const location = useLocation(); // This hook gives you access to the location object
  const { currentUser, loading } = useContext(AuthContext);
  const isLoggedIn = currentUser != null;
  const isScreenshotRoute = location.pathname.startsWith("/screenshot/");
  const isAdminRoute = location.pathname.startsWith("/admin"); // Check if the current route is the Admin route


   // Parse and handle @username routes, lowercase the first letter
   let usernamePath = location.pathname.match(/^\/@(\w+)/);
   if (usernamePath && usernamePath[1]) {
     // Extract username, remove '@' and lowercase the first letter
     let username = usernamePath[1];
     username = username.charAt(0).toLowerCase() + username.slice(1);
     // Redirect or handle the username as needed
     console.log('Normalized Username:', username);
   }
 

  // Determine if the specific headers should be shown
  const showHeader = !isAdminRoute && !isScreenshotRoute && (isLoggedIn || location.pathname !== "/") && !location.pathname.includes('/verifyEmail') && !location.pathname.includes('/ProfileSetup');
  const showSignUpHeader = (location.pathname.includes('/ProfileSetup') || location.pathname.includes('/verifyEmail')) && isLoggedIn;

  //Prevent rendering children routes until authentication loading state is resolved
  if (loading) {
    return (
      <div
        style={{
          height: "-webkit-fill-available",
          width: "100%",
          backgroundColor: "#323232",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: 'absolute',
          top:0,
          zIndex:1000
        }}
      >
        <img src="/remix-loading.svg" style={{ height: 55, marginBottom: 20 }} />
        <ProgressBar duration={10} maxDuration={1000} />
        <p style={{ fontFamily: 'quicksand', fontSize: 14, position: 'absolute', bottom: 30, color: 'white' }}>Loading...</p>
      </div>
    );
  }

  return (
    <>
      {showHeader && <MemoizedHeader />}
      {showSignUpHeader && <SignUpHeader />}
      {children}
    </>
  );
});
  
  
  

  if(isLoggedIn) {
    EditorStore.setShowTooltip(false);
    AuthenticationStore.setUserId(currentUser.uid)
  }
  
  const showBackground = location.pathname === "/" ||
                      location.pathname === "/remixes/" ||
                      location.pathname === "/search/" ||
                      location.pathname === "/filters/recent" ||
                      location.pathname === "/filters/featured" ||
                      location.pathname === "/filters/myfeed";

const backgroundImage = showBackground ? "url(../images/Background-feed.png)" : "";



  return (
    <>
      <React.StrictMode>
        <RequireEmailVerification>
        {/* <QueryClientProvider client={queryClient}> */}
        {/* <Elements key={"elements_" + Math.random()} stripe={stripePromise}> */}
        {/* <ThemeProvider key={"ThemeProvider_" + Math.random()} theme={theme}> */}
        <Suspense>
          {/* <Router> */}
            {/* <ReloadOnBack /> */}
            {/* <RouteTransitionProvider> */}

            <RouteWrapper>
              <div>
                <div id="event-manager"></div>
                {/* <Switch> */}
                <Routes>
  <Route path="/" element={isLoggedIn ? <Main key={Math.random()} /> : <HomeDefault />} />
  <Route path="/admin" element={<ProtectedAdminRoute><Admin /></ProtectedAdminRoute>} />
  <Route path="/messages" element={<MessagesWrapper />} />
  <Route path="/messages/:id" element={<MessagesWrapper />} />
  <Route path="/verifyEmail" element={<VerifyEmail />} />
  <Route path="/settings" element={<ProtectedPageRoute><Settings /></ProtectedPageRoute>} />
  <Route path="/room/:id" element={<ProtectedRoomRoute component={Room} key={Math.random()} />} />
  <Route path="/room/:roomId/comments/:commentId" exact element={<Room/>} />
  <Route path="/room/:roomId/comments/:commentId/replies/:replyId" exact element={<Room/>} />
  <Route path="/screenshot/" element={<MakeScreenShot />} />
  <Route path="/notifications" element={<Notifications />} />
  <Route path="/auth" element={<Auth key={Math.random()} />} />
  <Route path="/create" element={<CreateScreen key={Math.random()} />} />
  <Route path="/email-verification" element={<EmailVerification/>} />
  <Route path="/password-reset" element={<PasswordReset/>} />
  <Route path="/full/:id" element={<Full />} />
  <Route path="/signup" element={<SignUp />} />
  <Route path="/login" element={<Login />} />
  <Route path="/terms" element={<TermsOfUse/>} />
  <Route path="/privacy" element={<PrivacyPolicy/>} />
  {/* <Route path="/about" element={<About />} /> */}
  <Route path="/help" element={<Help />} />
  <Route path="/CreateInvite" element={<ProtectedPageRoute><CreateInvite /></ProtectedPageRoute>} />
  {/* <Route path="/record" element={<Record />} /> */}
  <Route path="/flow-not-found" element={<FlowNotFound />} />
  <Route path="/invite/:inviteCode" element={<InviteVerification />} />
  {/* Place the filter type routes here, before other generic dynamic routes */}
  <Route path="/filters/:filterType/" element={<Main />} />
  {/* Generic ID and Profile routes */}
  <Route path="/remixes/:remixId/" element={<Main />} />
  <Route path="/search/:searchId/" element={<Main />} />
  <Route path="/:id" element={<ProfilePage key={Math.random()}/>} />
  <Route path="/@:id" element={<ProfilePage key={Math.random()}/>} />
  <Route path="/ProfileSetup" element={<ProfileSetup/>} />
</Routes>

                {/* </Switch> */}
              </div>
              {/* </RouteTransitionProvider> */}
            </RouteWrapper>

          {/* </Router> */}
        </Suspense>
        {/* </ThemeProvider> */}
        {/* </Elements> */}
        {/* <ReactQueryDevtools /> */}
        {/* </QueryClientProvider> */}
        </RequireEmailVerification>
      </React.StrictMode>
      <div
  style={{
    position: "fixed",
    backgroundImage: backgroundImage,
    backgroundSize: "cover",
    top: "0px",
    height: "100vh",
    width: "100%",
    zIndex: -1,
  }}
></div>


         
          
       {/* <div
        style={{
          position: "absolute",
          backgroundColor: "black",
          top: "0px",
          height: "100vh",
          width: "100%",
          maxWidth:300,
          zIndex: 1000,
          left:77
        }}
      ></div> */}
 <SetAdvancedModal/>

    </>
  );
});

const jsx = (
  <>
    {/* <div
      rel="preload"
      as="image"
      style={{
        height: browserName === "safari" ? "-webkit-fill-available" : "100vh",
      }}
    > */}
    <AuthProvider>
      <NotificationProvider>
      <Router>
        <AppRoutes />
      </Router>
      </NotificationProvider>
    </AuthProvider>
    {/* </div> */}
  </>
);

//render normal showing Profile componenet
renderApp("root", hasRenderedRoot);
