import * as ElasticAppSearch from "@elastic/app-search-javascript";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useState } from "react";
// import ScrollMenu, { VisibilityContext } from "react-horizontal-scrolling-menu";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";

import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext";
import { RoomProvider } from "./RoomContext.js";
import { navigate } from "react-big-calendar/lib/utils/constants.js";
import { useNavigate } from "react-router-dom";

// import Header from "./Header";
// import MainFilter from "./MainFilter";
// import OtherFilter from "./OtherFilter";
// import PaymentModal from "./PaymentModal.js";
// import ReportModal from "./ReportModal";
// import RoomPost from "./RoomPost.js";
const Header = React.lazy(() => import("./Header"));
const MainFilter = React.lazy(() => import("./MainFilter"));
const OtherFilter = React.lazy(() => import("./OtherFilter"));
const PaymentModal = React.lazy(() => import("./PaymentModal.js"));
const ReportModal = React.lazy(() => import("./ReportModal"));
const RoomPost = React.lazy(() => import("./RoomPost.js"));
const RoomContext = createContext();
let adminLoggedIn = false;
let currentUser = null;
let roomsPerPage = window.innerWidth >= 1024 ? 6 : 4;
let firestore = firebase.firestore();
var x = window.matchMedia("(max-width: 800px)");
let isCurrentlyMobileTabletResolution;
let isCurrentlyDesktopResolution;
let typingTimer;
const Arrow = ({ text, className }) => {
  // return <div className={className}>{text}</div>;
  return (
    <i
      className="fas fa-chevron-right"
      style={{ color: "#A962F1", fontSize: 12 }}
    ></i>
  );
};
Arrow.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

// function LeftArrow() {
//   const { isFirstItemVisible, scrollPrev } =
//     React.useContext(VisibilityContext);

//   return (
//     <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//       <ArrowLeft />
//     </Arrow>
//   );
// }

// function RightArrow() {
//   // const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

//   return (
//     <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
//       <ArrowRight />
//     </Arrow>
//   );
// }

function isCurrentDevice(x) {
  if (x.matches) {
    isCurrentlyMobileTabletResolution = true;
    isCurrentlyDesktopResolution = false;
  } else {
    isCurrentlyDesktopResolution = true;
    isCurrentlyMobileTabletResolution = false;
  }
}
var eoptions = {
  search_fields: {
    description: {},
    collaborators: {},
    filters: {},
    thumbnail: {},
    title: {},
    date: {},
    flowid: {},
    comments: {},
    likes: {},
    creator: {},
    creatorprofilepic: {},
    username: {},
  },
  result_fields: {
    description: {
      raw: {},
    },
    collaborators: { raw: {} },
    filters: { raw: {} },
    thumbnail: { raw: {} },
    title: { raw: {} },
    date: { raw: {} },
    flowid: { raw: {} },
    comments: { raw: {} },
    likes: { raw: {} },
    creator: { raw: {} },
    creatorprofilepic: { raw: {} },
    username: { raw: {} },
  },
};
const MenuItem = ({ text, selected }) => {
  return (
    <div
      className={`menu-item ${selected ? "active" : ""}`}
      style={{ fontSize: 14, fontWeight: "bold" }}
    >
      {text}
    </div>
  );
};

var client = ElasticAppSearch.createClient({
  searchKey: "search-au4byfvb1sdmttpm5w1uw26s",
  endpointBase: "https://flowroom.ent.eu-west-1.aws.found.io",
  engineName: "flowroom",
});

isCurrentDevice(x); // Call listener function at run time
x.addListener(isCurrentDevice); // Attach listener function on state changes
export const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
export const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
export const Menu = (list, selected) =>
  list.map((el) => {
    const { name } = el;

    return <MenuItem text={name} key={name} selected={selected} />;
  });

const RoomPlaceholder = () => {
  return (
    <div className="room-placeholder">
      {/* Your placeholder content, e.g., gray boxes, shimmer effects, etc. */}
      <div
        style={{
          height: 750,
          width: "100%",
          background: "rgb(28, 28, 28)",
          boxShadow: "rgb(0, 0, 0) 0px 0px 17px -7px",
          borderRadius: 20,
          overflow: "hidden",
          maxWidth: 503,
          margin: "auto",
        }}
        className="skeleton-card"
      ></div>
      <style>
        {`
    .skeleton-card {
      overflow: hidden;
      position: relative;
      background: rgb(28, 28, 28);
      box-shadow: rgb(0, 0, 0) 0px 0px 17px -7px;
      border-radius: 20px;
      height: 782px;
      width: 100%;
    }

    .skeleton-card:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background:rgb(28, 28, 28);
      transform: translateX(-100%);
      animation: shimmer 2s infinite;
      overflow:hidden;
      borderRadius:20px;
    }

    @keyframes shimmer {
      0% { transform: translateX(-100%); }
      100% { transform: translateX(100%); }
    }
  `}
      </style>
    </div>
  );
};

const Admin = observer(() => {
  const WidgetStore = useContext(WidgetStoreContext);
  const [rooms, setRooms] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = window.innerWidth < 800 ? 4 : 10;
  const [lastDocs, setLastDocs] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 800);
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState(''); // State to store the entered password
  const [adminLoggedIn, setAdminLoggedIn] = useState(false); // State to manage admin login status
  const EditorStore = useContext(EditorStoreContext);
  const navigate = useNavigate();


  function smoothScrollToTop(duration) {
    const targetPosition = 0;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
      else window.scrollTo(0, targetPosition); // Ensure it scrolls to the very top
    }

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      loadRooms(currentPage);
    }, 100);
  
    // Cleanup function
    return () => clearTimeout(timeoutId);
  }, [currentPage, WidgetStore.currentlySelectedFilter]);
  

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 800;
      setIsMobileView(isMobile);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    smoothScrollToTop(100); // Duration is 2000 milliseconds (2 seconds)
  };

  // const SelectionItems = () => {
  //   const [items, setItems] = useState([{}, {}, {}]);
  //   const [selected, setSelected] = useState([]);
  //   return (
  //     <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
  //       {items.map(({ id }) => (
  //         <Menu key={id + Math.random()} />
  //       ))}
  //     </ScrollMenu>
  //   );
  // };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value); // Update the password state with the entered value
  };
  const handleLogin = () => {
    const hardcodedPassword = 'adminXh8304AmdAUenXJHDkad39977'; // Replace with your actual hardcoded password
    if (password === hardcodedPassword && currentUser) {
      // Set admin login status in Firestore
      const adminRef = firebase.firestore().collection('admin').doc(currentUser.displayName);
      adminRef.set({
        username: currentUser.displayName,
        isAdminLoggedIn: true
      }, { merge: true })
      .then(() => {
        console.log('Admin login status set in Firestore');
        setAdminLoggedIn(true);
        EditorStore.setAdminIsLoggedIn(true);
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
    } else {
      alert('Incorrect password');
    }
  };

  const handleLogout = () => {
    EditorStore.setAdminIsLoggedIn(false);
    localStorage.removeItem("adminIsLoggedIn"); // Clear admin login state from localStorage on logout
  };
  const loadRooms = async (page) => {
    setIsLoading(true);

    let isMounted = true;
    try {
      let query;
      // Logic for selecting the query based on the currently selected filter
     if (WidgetStore.currentlySelectedFilter === "Recent") {
        query = firestore
          .collection("flows")
          .where("visibility", "==", "public")
          .where("isPinned", "==", false)
          .orderBy("date", "desc");
      } else if (WidgetStore.currentlySelectedFilter === "Featured") {
        query = firestore
          .collection("flows")
          .where("isFeatured", "==", true)
          .where("isPinned", "==", false);
      } 

      let querySnapshot;
      if (page === 0 || lastDocs[page - 1] === undefined) {
        querySnapshot = await query.limit(pageSize).get();
      } else {
        querySnapshot = await query
          .startAfter(lastDocs[page - 1])
          .limit(pageSize)
          .get();
      }

      const newRooms = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (querySnapshot.docs.length > 0) {
        setLastDocs((prevLastDocs) => {
          const newLastDocs = [...prevLastDocs];
          newLastDocs[page] = querySnapshot.docs[querySnapshot.docs.length - 1];
          return newLastDocs;
        });
      }

      if (isMounted) {
        setRooms(newRooms);
        setIsLoading(false); // Ensure this is called after successfully fetching data
      }
    } catch (e) {
      console.log(e);
    }

    return () => {
      isMounted = false;
    };
  };


  return (
    <>
      <div
        style={{
          width: isMobileView ? "100%" : "100%",
          maxWidth: isMobileView ? "100%" : 1059,
          margin: "auto",
          paddingLeft: isMobileView ? "20px" : "10px",
          paddingRight: isMobileView ? "20px" : "10px",
          position: "relative",
        }}
      >
        <nav
          key={Math.random() + Math.random() + Math.random() + Math.random()}
          className="main-nav nav-wrap-step"
          style={{ position: "relative", zIndex: 100000 }}
        >
          <div
            key={Math.random() + Math.random() + Math.random() + Math.random()}
            id="nav-wrap"
          >
            <MainFilter
              key={
                Math.random() + Math.random() + Math.random() + Math.random()
              }
            />

            <div
              key={
                Math.random() + Math.random() + Math.random() + Math.random()
              }
              style={{ display: "flex" }}
            >
              <div
                key={
                  Math.random() + Math.random() + Math.random() + Math.random()
                }
                className="scroll-menu-wrap"
              >
                {/* <SelectionItems
                  key={
                    Math.random() +
                    Math.random() +
                    Math.random() +
                    Math.random()
                  }
                /> */}
              </div>
            </div>
            <OtherFilter
              key={
                Math.random() + Math.random() + Math.random() + Math.random()
              }
            />
          </div>

          <div
            key={Math.random() + Math.random() + Math.random() + Math.random()}
            id="search-wrap"
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
              marginRight: 0,
            }}
          >
            <input
              key={
                Math.random() + Math.random() + Math.random() + Math.random()
              }
              className="searchInput search-step"
              onClick={() => {}}
              type="text"
              placeholder="Search for flows, #tags, or @usernames"
              onKeyUp={(e) => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(() => {
                  let that = this;
                  if (e.target.value.trim() == "") {
                    this.setState({ rooms: [], roomsLoaded: false });
                  }
                  client
                    .search(e.target.value, eoptions)
                    .then((resultList) => {
                      resultList.results.forEach((result) => {
                        let fresult = {
                          description: result.getRaw("description"),
                          collaborators: result.getRaw("collaborators"),
                          filters: result.getRaw("collaborators"),
                          title: result.getRaw("title"),
                          date: result.getRaw("date"),
                          flowId: result.getRaw("flowid"),
                          comments: result.getRaw("comments"),
                          likes: result.getRaw("likes"),
                          creator: result.getRaw("creator"),
                          creatorProfilePic: result.getRaw("creatorprofilepic"),
                          userId: result.getRaw("userId"),
                          urlPhoto: result.getRaw("thumbnail"),
                          thumbnail: result.getRaw("thumbnail"),
                          roomWidth: 640,
                          roomHeight: 640,
                          id: result.getRaw("flowid"),
                          username: result.getRaw("username"),
                        };
                        console.log(fresult);
                        that.addRoomSearch(fresult);
                        that.setState({
                          rooms: rooms,
                          roomsLoaded: true,
                        });
                      });
                    })
                    .catch((error) => {
                      console.log(`error: ${error}`);
                    });
                }, 500);
              }}
            />

            <div
              key={
                Math.random() + Math.random() + Math.random() + Math.random()
              }
              className="search-icon-main"
            >
              <img src="./images/search.svg" />
            </div>
          </div>
        </nav>

        <main
          style={{
            position: "relative",
            maxWidth: 1089,
            width: "100%",
            margin: "auto",
          }}
        >
          <div className="room-container">
            <div className="room-content">
              <div
                className={`room-grid ${isMobileView ? "single-column" : ""}`}
              >
                {isLoading
                  ? // Display placeholders
                    Array.from({ length: pageSize }, (_, index) => (
                      <RoomPlaceholder key={index} />
                    ))
                  : // Display actual rooms
                    rooms.map((room, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <RoomProvider value={room}>
                          <RoomPost />
                        </RoomProvider>
                      </div>
                    ))}
                {/* {rooms.length == 0 ? (
                  <div
                    style={{
                      position: "absolute",
                      margin: "auto",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      top: -97,
                      pointerEvents: "none",
                    }}
                  >
                    <p className="no-items">There are currently no flows.</p>
                  </div>
                ) : (
                  ""
                )} */}
              </div>
              <div className="pagination">
                <div
                  style={{
                    height: "47px",
                    width: "140px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "none",
                    fontFamily: "quicksand",
                    fontSize: 15.5,
                    backgroundImage: "url(/images/previous.svg)",
                    backgroundRepeat: "no-repeat",
                  }}
                  onClick={() => handlePageChange(currentPage - 1)}
                ></div>
                <div
                  style={{
                    height: "47px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "none",
                    fontFamily: "quicksand",
                    fontSize: 15.5,
                    backgroundImage: "url(/images/next.svg)",
                    backgroundRepeat: "no-repeat",
                    maxWidth: 90,
                    backgroundSize: "100%",
                  }}
                  onClick={() => {
                    handlePageChange(currentPage + 1);
                  }}
                ></div>
              </div>
            </div>
          </div>
        </main>
        <ReportModal />
        <PaymentModal />
      </div>
    </>
  );



});

export default React.memo(Admin);
