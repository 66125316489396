import * as ElasticAppSearch from "@elastic/app-search-javascript";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
// import ScrollMenu, { VisibilityContext } from "react-horizontal-scrolling-menu";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { ProfileStoreContext } from "../../store/ProfileStore.js";

import { firebase } from "../firebase/firebase";
import PaymentModal from "./PaymentModal.js";
import ReportModal from "./ReportModal";
import { RoomProvider } from "./RoomContext.js";
import RoomPost from "./RoomPost.js";
import PromoAgreement from "./PromoAgreement.js";
import { Config } from "./config/Config";
import SigninM from "./SigninM.js";
import { AuthContext } from "./AuthContext";
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, Navigate  } from 'react-router-dom';


const masonryOptions = {
  transitionDuration: 0,
};
let currentUserString = localStorage.getItem("flowroom-user");

const imagesLoadedOptions = { background: ".my-bg-image-el" };

const getPostWidth = () => {
  if (window.innerWidth > 2000) {
    return window.innerWidth * 0.25;
  } else if (window.innerWidth > 1430) {
    return window.innerWidth * 0.35;
  } else if (window.innerWidth > 640) {
    return window.innerWidth * 0.4;
  } else {
    return window.innerWidth * 0.9;
  }
};
const roomsPerPage = 4;  // Limit rooms per page to 4
let lastDocs = [];  // To keep track of the last document snapshot for each page

let firestore = firebase.firestore();
var x = window.matchMedia("(max-width: 800px)");
let isCurrentlyMobileTabletResolution;
let isCurrentlyDesktopResolution;
let typingTimer;
const Arrow = ({ text, className }) => {
  // return <div className={className}>{text}</div>;
  return (
    <i
      className="fas fa-chevron-right"
      style={{ color: "#A962F1", fontSize: 12 }}
    ></i>
  );
};
Arrow.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

// function LeftArrow() {
//   const { isFirstItemVisible, scrollPrev } =
//     React.useContext(VisibilityContext);

//   return (
//     <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//       <ArrowLeft />
//     </Arrow>
//   );
// }

// function RightArrow() {
//   const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

//   return (
//     <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
//       <ArrowRight />
//     </Arrow>
//   );
// }

function isCurrentDevice(x) {
  if (x.matches) {
    isCurrentlyMobileTabletResolution = true;
    isCurrentlyDesktopResolution = false;
  } else {
    isCurrentlyDesktopResolution = true;
    isCurrentlyMobileTabletResolution = false;
  }
}
var eoptions = {
  search_fields: {
    description: {},
    collaborators: {},
    filters: {},
    thumbnail: {},
    title: {},
    date: {},
    flowid: {},
    comments: {},
    likes: {},
    creator: {},
    creatorprofilepic: {},
    username: {},
  },
  result_fields: {
    description: {
      raw: {},
    },
    collaborators: { raw: {} },
    filters: { raw: {} },
    thumbnail: { raw: {} },
    title: { raw: {} },
    date: { raw: {} },
    flowid: { raw: {} },
    comments: { raw: {} },
    likes: { raw: {} },
    creator: { raw: {} },
    creatorprofilepic: { raw: {} },
    username: { raw: {} },
  },
};
const MenuItem = ({ text, selected }) => {
  return (
    <div
      className={`menu-item ${selected ? "active" : ""}`}
      style={{ fontSize: 14, fontWeight: "bold" }}
    >
      {text}
    </div>
  );
};

var client = ElasticAppSearch.createClient({
  searchKey: "search-au4byfvb1sdmttpm5w1uw26s",
  endpointBase: "https://flowroom.ent.eu-west-1.aws.found.io",
  engineName: "flowroom",
});

isCurrentDevice(x); // Call listener function at run time
x.addListener(isCurrentDevice); // Attach listener function on state changes
export const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
export const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
export const Menu = (list, selected) =>
  list.map((el) => {
    const { name } = el;

    return <MenuItem text={name} key={name} selected={selected} />;
  });

  let currentTabId = "flows";
  let userId = "";
  let urlPhoto = "";
  let flowIds = {};
  let rooms = [];
  let roomPosts = [];
  let favorites = [];
  let followers = [];
  let followings = [];
  let favoriteUserIds = {};
  let lastIndexFlows = null;
  let lastIndexLikes = null;
  let lastIndexFollowers = null;
  let lastIndexFollowings = null;
  let followersPerPage = window.innerWidth >= 1024 ? 9 : 9;
  let followingsPerPage = window.innerWidth >= 1024 ? 9 : 9;
  let roomFilter = "date";
  let ROOM_ASPECT_RATIO = 1;
  let timer = null;
  let thumbnailListeners = {};
  let limitFlowIds = [];
  let postWidth = getPostWidth();
  // let postHeight = postHeight;
  
  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  let marginVertical = isMobile ? 40 : 20;
  let marginTopHeader = isMobile ? 55 : 55;
  let username = "";

  const style = {
    height: 500,
    width: 500,
    border: "1px solid green",
    margin: 6,
    padding: 8,
  };
  
const ScrollProfile = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const [rooms, setRooms] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 4; // Number of items per page, adjust as needed
  const [lastDocs, setLastDocs] = useState([]);

  const [tryAgain, setTryAgain] = useState(false);
  const [hasMoreRooms, setHasMoreRooms] = useState(true);
  const [roomsList, setRoomsList] = useState(true);
  const [openPromo, setOpenPromo] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const [lastDoc, setLastDoc] = useState(null);
  const [firstDoc, setFirstDoc] = useState(null);
  const [loadingFlows, setLoadingFlows] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ProfileStore = useContext(ProfileStoreContext)
  const { id } = useParams();


  useEffect(()=>{
    
  },[ProfileStore.profileItemSelected])

  function smoothScrollToTop(duration) {
    const targetPosition = 0;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
      else window.scrollTo(0, targetPosition); // Ensure it scrolls to the very top
    }

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
  }


  useEffect(()=>{
    
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  },[])

  useEffect(() => {
    loadRooms(currentPage);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
    // WidgetStore.setCurrentlySelectedFilter("recent");
  }, [currentPage, WidgetStore.currentlySelectedFilter]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    smoothScrollToTop(100); // Duration is 2000 milliseconds (2 seconds)

  };

const loadRooms = async (page) => {
  // setRooms([{},{},{},{}])
  setIsLoading(true);

  setHasMoreRooms(true);
  let roomsArr = [];
  let pinnedRooms = [];
  let favoritesRooms = [];
  let excludedFlowIds = new Set();

  // Determine which user ID to use for fetching useLater based on the context
  let userIdForQuery = id; // Assume 'id' is the ID of the profile being viewed

  // Fetch the useLater flows to exclude them for any scenario except when currentUser views their own 'useLater'
  // if (currentUser && props.filterBy !== "useLater") {
  //   const useLaterSnapshot = await firebase.firestore().collection("useLater")
  //     .where("username", "==", id)
  //     .get();
  //   // excludedFlowIds = new Set(useLaterSnapshot.docs.map(doc => doc.data().flowId));
  // }

  if (props.filterBy === "useLater") {
      // setIsLoading(true);
    
      try {
        const bookmarksSnapshot = await firestore.collection("bookmarks")
          .where("username", "==", currentUser.displayName)
          .get();
    
        const flowIds = bookmarksSnapshot.docs.map(doc => doc.data().flowId);
    
        let newRooms = [];
    
        // Only proceed with the query if there are flow IDs to filter on
        if (flowIds.length > 0) {
          const flowsQuery = firestore.collection("flows")
            .where("flowId", "in", flowIds)
            .orderBy("date", "desc");
    
          const querySnapshot = await flowsQuery.get();
    
          newRooms = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
        } else {
          console.log("No bookmarks found for user, or no flows match the bookmarked IDs.");
        }
    
        setRooms(newRooms);
       
      } catch (e) {
        console.error("Error loading rooms with bookmarked flows: ", e);
      }
    
      setIsLoading(false);
    
    
  }
  
  
  


  
  if (props.filterBy === "favorites") {
    let excludeFlowIds = new Set(); // Set to store flow IDs to exclude
  
    // Optionally fetch excluded Flow IDs as in the "flows" block
    // This might be filled based on some criteria as shown previously
  
    const favoritesQuery = firebase.firestore().collection("likes")
      .where("username", "==", userIdForQuery);  
    let favoritesSnapshot;
    if (currentPage === 0) {
      favoritesSnapshot = await favoritesQuery.limit(pageSize).get();
    } else {
      favoritesSnapshot = await favoritesQuery.startAfter(lastDocs[currentPage - 1]).limit(pageSize).get();
    }
  
    const favoritesFlowIds = favoritesSnapshot.docs.map(doc => doc.id.split(":")[1]);
    const validFavoritesFlowIds = favoritesFlowIds.filter(flowId => !excludeFlowIds.has(flowId));
  
    // Fetch each document by ID and filter by 'visibility' in the client-side
    const favoritesRoomsPromises = validFavoritesFlowIds.map(flowId => 
      firebase.firestore().collection("flows").doc(flowId).get()
    );
  
    const favoritesRoomsSnapshots = await Promise.all(favoritesRoomsPromises);
    let favoritesRooms;
    if(currentUser !== null && currentUser.displayName !== userIdForQuery) {
    favoritesRooms = favoritesRoomsSnapshots
      .filter(doc => doc.exists && doc.data().visibility === "public")
      .map(doc => ({ id: doc.id, ...doc.data() }));
  
    setRooms(favoritesRooms);
    } else {
      favoritesRooms = favoritesRoomsSnapshots
      .filter(doc => doc.exists)
      .map(doc => ({ id: doc.id, ...doc.data() }));
  
       setRooms(favoritesRooms);
    }
  
    if (favoritesSnapshot.docs.length > 0) {
      setLastDocs((prevLastDocs) => {
        const newLastDocs = [...prevLastDocs];
        newLastDocs[currentPage] = favoritesSnapshot.docs[favoritesSnapshot.docs.length - 1];
        return newLastDocs;
      });
    }
  
    // Adjust pagination based on the number of rooms actually displayed
    if (favoritesRooms.length < pageSize) {
      setHasMoreRooms(false); // No more rooms to fetch
    } else {
      setHasMoreRooms(true);
    }
  }
  
  
  if (props.filterBy === "flows") {
    setIsLoading(true);

    let isMounted = true;
    try {
        const collectionsToQuery = ["pinned", "reshared", "paidPromo", "bookmarks"];
        const statusIDs = {};

        // Fetch IDs from all specified collections
        for (const collectionName of collectionsToQuery) {
            const querySnapshot = await firestore.collection(collectionName)
                .where("username", "==", userIdForQuery)
                .get();
            statusIDs[collectionName] = new Set(querySnapshot.docs.map(doc => doc.data().flowId));
        }

        console.log("Status IDs fetched for augmentation:", statusIDs);

        let baseQuery = firestore.collection("flows")
            .where("username", "==", userIdForQuery)
            .orderBy("date", "desc");

        if (currentUser.displayName !== id) {
            baseQuery = baseQuery.where("visibility", "==", "public");
        }

        let extraItemsNeeded = 0;
        let querySnapshot;
        let newRooms = [];

        do {
            // Adjust fetch size dynamically
            let fetchSize = pageSize + extraItemsNeeded;

            if (currentPage === 0 || !lastDocs[currentPage - 1]) {
                querySnapshot = await baseQuery.limit(fetchSize).get();
            } else {
                querySnapshot = await baseQuery.startAfter(lastDocs[currentPage - 1]).limit(fetchSize).get();
            }

            newRooms = querySnapshot.docs
                .filter(doc => !statusIDs['bookmarks'].has(doc.id))
                .map(doc => {
                    const docData = doc.data();
                    const flowId = doc.id;

                    return {
                        id: flowId,
                        ...docData,
                        isPinned: statusIDs['pinned'].has(flowId),
                        pinnedBy: statusIDs['pinned'].has(flowId) ? userIdForQuery : undefined,
                        isReshared: statusIDs['reshared'].has(flowId),
                        resharedBy: statusIDs['reshared'].has(flowId) ? userIdForQuery : undefined,
                        isPaidPromo: statusIDs['paidPromo'].has(flowId),
                        paidPromoBy: statusIDs['paidPromo'].has(flowId) ? userIdForQuery : undefined,
                    };
                });

            // Calculate how many more items are needed if any are filtered out
            extraItemsNeeded = fetchSize - newRooms.length;
        } while (newRooms.length < pageSize && extraItemsNeeded > 0 && !querySnapshot.empty);

        newRooms.sort((a, b) => b.isPinned - a.isPinned);
        console.log('New Rooms with augmented status:', newRooms);

        if (querySnapshot.docs.length > 0) {
            setLastDocs(prevLastDocs => [...prevLastDocs, querySnapshot.docs[querySnapshot.docs.length - 1]]);
        }

        setRooms(newRooms);
    } catch (e) {
        console.error("Error loading rooms with augmented statuses: ", e);
    }

    return () => {
        isMounted = false;
    };
}







  

};


// if(loadingFlows) {
//   return(<div>loading</div>)
// }



  return (
    <>
      <div
        style={{
          width: window.innerWidth <= 700 ? 485 : "100%",
          maxWidth: window.innerWidth <= 700 ? "100%" : 1010,
          margin: "auto",
          paddingLeft: 20,
          paddingRight: 20,
   
        }}
      >
        <main
          style={{
            position: "relative",
            // maxWidth: 960,
            width: "100%",
            margin: "auto",
          }}
        >
          {" "}
          <div className="room-container">
            <div className="room-content">
              <div className={rooms.length == 0 ? "" : "room-grid"}>
              {/* {!isLoading && rooms.length === 0 ? (<div style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', marginTop:150, position: 'relative',
    left: 0}}>
                  <img src="/images/not-available-icon.svg" style={{height:80, width:80}} />
                  <p style={{fontFamily:'quicksand', color:'white', fontSize:17, marginTop:10}}>The are currently no flows.</p>
                </div>):""}
              */}
                    {rooms.map((room, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <RoomProvider value={room}>
                          <RoomPost />
                        </RoomProvider>
                      </div>
                    ))}
                {rooms.length == 0 ? (
                  <div
                    style={{
                      position: "relative",
                      margin: "auto",
                      height: "100%",
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      pointerEvents: "none",
                    }}
                  >
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}> 
                                    <img src="images/not-available-icon.svg" style={{height:80, width:80, marginBottom:30}}/>

                    <p className="no-items">There are currently no flows.</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {rooms.length !== 0 ? (<div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom:50
              }}
            >
              <div
                style={{
                  height: 47,
                  width: 140,
                  backgroundImage:`url(/images/previous.svg)`,
                  backgroundRepeat:'no-repeat',
                  marginTop:20,
                  cursor:'pointer',
                  marginBottom:30
                }}
                onClick={() => handlePageChange(currentPage - 1)}
              >
              </div>
              <div
                 style={{
                  height: 47,
                  width: 90,
                  backgroundImage:`url("/images/next.svg")`,
                  backgroundRepeat:'no-repeat',
                  marginTop:20,
                  cursor:'pointer',
                  marginBottom:30
                }}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                 
              </div>
            </div>):""}
          </div>
        </main>
        <ReportModal />
        <PaymentModal />
        

      </div>
    </>
  );
});

const FlowCard = ({ data: { id, thumbnail } }) => (
  <div
    style={{
      border: "0px ",
      position: "relative",
    }}
    className="card"
  >
    {/* Here you need to replace RoomPostMemo with the actual component you want to render, and pass the necessary props */}
    {/* <RoomPostMemo comments={comments} details={details} /> */}
    test
  </div>
);
export default React.memo(ScrollProfile);
