import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { observer } from "mobx-react-lite";
import React, { useEffect, useContext, useState } from "react";
// import { Config } from "../../../config/Config";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import CollabPlusDetails from "./CollabPlusDetails";

import Hashids from "hashids";
import { v4 as uuid } from "uuid";
import { ProfileStoreContext } from "../../store/ProfileStore.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { TabStoreContext } from "../../store/TabStore.js";

import { firebase } from "../firebase/firebase";
import NameAndDate from "./NameAndDate";
// store
import ReactModal from "react-modal";
import BlockModal from "./BlockModal";
import CollaboratorsDetails from "./CollaboratorsDetails.js";
import ErrorModal from "./ErrorModal";
import FeatureRequestModal from "./FeatureRequestModal.js";
import ReportModal from "./ReportModal";
import styles from './SidebarMenu.module.css'; // Import as a module

import { AuthContext } from "../Home/AuthContext.js"; // Make sure to import the correct context
import LoginM from "./LoginM.js";
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, Navigate  } from 'react-router-dom';

var moment = require("moment");
let canPin = true;
let parts = window.location.pathname.split("/");
// let parts = window.location.pathname.split("/");
let lastSegment = parts.pop() || parts.pop();

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#222222",
        borderWidth: 4,
      },
      "&:hover fieldset": {
        borderColor: "#6371F6",
        borderWidth: 4,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6371F6",
      },
    },
  },
})(TextField);

const DetailsMenu = observer(({ currentUser }) => {
  const FlowStore = useContext(FlowStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const ProfileStore = useContext(ProfileStoreContext);
  const TabStore = useContext(TabStoreContext);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState();
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState();
  const [isPinned, setIsPinned] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  const [pending, setPending] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const [lastClickTime, setLastClickTime] = useState(false);

  const WidgetStore = useContext(WidgetStoreContext);
  let firestore = firebase.firestore();
  let { id, roomId } = useParams(); // If you need to access params
  let flowId = id !== undefined ? id : roomId;

  function getCollaboratorsForFlow(flowId) {
    return firebase
      .firestore()
      .collection("collaborators")
      .where("additionalData.flowId", "==", flowId)
      .get()
      .then((querySnapshot) => {
        const collaborators = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          collaborators.push({
            collaboratorId: data.additionalData.collaboratorId,
            flowId: data.additionalData.flowId,
            receiver: data.receiver,
            sender: data.sender,
            timestamp: data.timestamp,
            skill: data.additionalData.skill,
          });
        });

        return collaborators;
      });
  }

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted
  
    // IIFE to handle async operation
    (async () => {
      const docLike = await firebase
        .firestore()
        .collection("blocked")
        .doc(AuthenticationStore.userId + ":" + EditorStore.username)
        .get();
  
      if (docLike.exists && isMounted) {
        setIsBlocked(true);
      }
    })();
  
    // Cleanup function
    return () => {
      isMounted = false; // set flag to false when component unmounts
    };
  }, []); // Dependency array is empty, so this effect runs only once on mount
  




  useState(() => {
 
    const flowIdToSearch = flowId;
    getCollaboratorsForFlow(flowIdToSearch).then((collaborators) => {
      EditorStore.setCollaborators(collaborators);
      console.log("collaborators", collaborators);
    });

    // setTimeout(() => {
    //   setIsPinned(EditorStore.isPinned);
    // }, 5000);

    if (flowId == "new") {
      setShowDetails(false);
    } else {
      setShowDetails(true);
    }
  }, [flowId]);

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted
    // IIFE to handle async operation
    (async () => {
      const docLike = await firebase
        .firestore()
        .collection("pinned")
        .doc(AuthenticationStore.userId + ":" + flowId)
        .get();
  
      if (docLike.exists && isMounted) {
        setIsPinned(true);
      } else {
        setIsPinned(false);

      }
    })();
  
    // Cleanup function
    return () => {
      isMounted = false; // set flag to false when component unmounts
    };
  }, []); // Dependency array is empty, so this effect runs only once on mount
  



  const pinFlow = async () => {
    if (!AuthenticationStore.userId) {
      console.log("User not authenticated.");
      AuthenticationStore.setShowModal(true);
      return;
    }
  
    const currentTime = Date.now();
    if (currentTime - lastClickTime < 1000) {
      console.log("Action too frequent. Try again later.");
      setTryAgain(true);
      return;
    }
    setLastClickTime(currentTime);
  
    setPending(true);
    const docPin = await firebase
      .firestore()
      .collection("pinned")
      .doc(AuthenticationStore.userId + ":" + flowId)
      .get();
  
    if (docPin.exists) {
      setIsPinned(false);
      setPinned(pinned - 1);
      await firebase
        .firestore()
        .collection("pinned")
        .doc(AuthenticationStore.userId + ":" + flowId)
        .delete();
      setPending(false);
    } else {
      setIsPinned(true);
      setPinned(pinned + 1);
  
      await firebase
        .firestore()
        .collection("pinned")
        .doc(AuthenticationStore.userId + ":" + flowId)
        .set({
          flowId: flowId,
          likeId: AuthenticationStore.userId + ":" + flowId,
          userId: AuthenticationStore.userId,
          timestamp: new Date().toISOString(),
        }, { merge: true });
      setPending(false);
    }
  };
  


  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  return (
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        // backgroundColor: "#222222",
        width: "100%",
        zIndex: 10,
        position: "relative",
      }}
    >
      {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: 300,
                    background: "black",
                    padding: 10,
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: 16,
                      marginLeft: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Share
                  </p>
                  <i
                    onClick={() => {
                      document.getElementsByClassName(
                        "editor-menu"
                      )[0].style.paddingLeft = "78px";
                    }}
                    className="fas fa-times"
                    style={{ color: "white", fontSize: 14, cursor: "pointer" }}
                  ></i>
                </div> */}
      <div
        style={{
          backgroundColor: "rgb(63, 63, 62);",
          paddingLeft: 23,
          display: "flex",
          width: "calc(100% - 211px)",
          alignItems: "center",
          marginTop: 27,
        }}
      >
        <img src="/images/info-exclamation.svg" />
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 18,
            marginLeft: 10,
          }}
        >
          Info
        </p>
        {/* 
        <FontAwesomeIcon
          icon={faTimesCircle}
          color="#f06263"
          size={"4x"}
          style={{
            position: "absolute",
            right: "16px",
            top: "16px",
            cursor: "pointer",
            fontSize: 37,
          }}
          onClick={() => {
            document.getElementsByClassName(
              "editor-menu"
            )[0].style.paddingLeft = "78px";
            // document.getElementById(
            //   "horizontal-tab-remix-tab"
            // ).style.background = "none";

            document.getElementsByClassName("remix-menu")[0].style.display =
              "none";
          }}
        /> */}
        
      <div     onClick={() => {
            TabStore.setClosePanel(true);
            
            // if( document.getElementById(
            //   "vertical-tab-details-tab"
            // ) !== null) {
            // document.getElementById(
            //   "vertical-tab-details-tab"
            // ).style.backgroundColor = "transparent";
            
            // document.getElementById(
            //   "vertical-tab-details-tab"
            // ).style.borderRadius = "0px";
            // }


            // document.getElementsByClassName("iframe-container")[0].style.width =
            //   "calc(100% - 125px)";

            // document.getElementById(
            //   "horizontal-tab-remix-tab"
            // ).style.background = "none";
          }} className={styles.closeButton} style={{ backgroundImage: 'url(/images/close-circle.svg)', backgroundSize: 'contain' }}></div>

      </div>

      {showDetails === true ? (
        <div style={{ display: "flex", maxWidth: 300,     marginTop: 20,
        position: 'relative' }}>
          <NameAndDate
            urlPhoto={EditorStore.urlPhoto}
            username={EditorStore.username}
            date={moment(EditorStore.date).fromNow()}
          />
          <div>
            <CollabPlusDetails
              collaborators={EditorStore.collaborators}
              flowId={flowId}
            />
          </div>

          <div
            onClick={() => {
              if (currentUser == null) {
                AuthenticationStore.setShowModal(true);
                return;
              }
              let options = document.getElementById(
                `flow-options-${EditorStore.flowId}-desktop`
              );
              if (options.style.display === "none") {
                options.style.display = "block";
              } else {
                options.style.display = "none";
              }
            }}
            style={{
              position: "absolute",
              right: 17,
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
          >
            {/* <div
              style={{
                backgroundColor: "#F06263",
                height: 7,
                width: 7,
                borderRadius: "50%",
                marginRight: "3.8px",
              }}
            ></div>
            <div
              style={{
                backgroundColor: "#F06263",
                height: 7,
                width: 7,
                borderRadius: "50%",
                marginRight: "3.8px",
              }}
            ></div>
            <div
              style={{
                backgroundColor: "#F06263",
                height: 7,
                width: 7,
                borderRadius: "50%",
                marginLeft: 0,
              }}
            ></div> */}
            <img
              src="/images/more-info.svg"
              style={{ right: 2, position: "absolute", top: 10 }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              position: "absolute",
              flexDirection: "column",
              top: "117px",
            }}
          >
            <FontAwesomeIcon
              key={Math.random() + "icon" + new Date()}
              icon={faExclamationTriangle}
              color="#FFFFFF"
              style={{
                display: "flex",
                marginBottom: 10,
                fontSize: "70px",
                position: "relative",
              }}
            />
            <p
              style={{
                position: "relative",
                fontFamily: "Quicksand",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "255px",
                fontSize: 16,
                left: 0,
                textAlign: "center",
              }}
            >
              There are no details for this flow, yet.
            </p>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "rgb(63, 63, 62);",
   
    height: 'calc(-204px + 100vh)',
    overflowY: 'scroll',
    width: 'calc(100% - 23px)',
    marginTop: 10
        }}
      >
        <p
          style={{
            color: "white",
            // fontSize: 11.4,
            textAlign: "center",

            position: "relative",
            top: "0px",
            marginLeft: 25,
            marginRight: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 100,
            fontSize: 14,
            color: "#FFFFFF",
            opacity: 0.6,
            zIndex: 1000,
            textAlign: "left",
            wordBreak: "break-word",
          }}
        >
          {EditorStore.currentFlowDescription}
        </p>
      </div>
      <div
        id={`flow-options-${flowId}-desktop`}
        style={{
          backgroundColor: "#1C1C1C",
          height: "100vh",
          width: "300px",
          position: "absolute",
          top: 130,
          display: "none",
          zIndex: 10000,
        }}
      >
        <div
          style={{
            display: "flex",

            marginBottom: 10,
            padding: 20,
            background: "#1C1C1C",
            height: "100%",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            onClick={async () => {
              pinFlow()
            }}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <img src="/images/Frame.svg" style={{ height: 20 }} />
            <p
              style={{
                marginLeft: 10,
                fontFamily: "quicksand",
                color: "white",
                cursor: "pointer",
              }}
            >
              {!isPinned ? "Pin Flow" : "Unpin Flow"}
            </p>
          </div>
          {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 15,
                }}
              >
                <FontAwesomeIcon
                  key={Math.random() + "icon" + new Date()}
                  icon={faUser}
                  color="#4FD975"
                  size={"1x"}
                  style={{ display: "flex" }}
                />
                <p
                  style={{
                    marginLeft: 10,
                    fontFamily: "quicksand",
                    color: "#4FD975",
                    cursor: "pointer",
                  }}
                >
                  Follow {`@${props.username}`}
                </p>
              </div> */}
          <div
            onClick={() => {
              if (currentUser == null) {
                // AuthenticationStore.setShowModal(true);
                return;
              }
              let roomData = {
                blocked: EditorStore.username,
                user: firebase.auth().currentUser.displayName,
              };

              if (firebase.auth().currentUser.displayName !== EditorStore.username) {
                // WidgetStore.setShowBlockModal(true);

                WidgetStore.setBlockModal(true);
                WidgetStore.setCurrentlyBlockingUser(EditorStore.username);
              } else {
                // WidgetStore.setShowErrorModal(true);
                WidgetStore.setErrorModalMessage("");

                WidgetStore.setErrorModalMessage("You can not block yourself");
                setOpenModalError(true);
              }
            }}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <img src="/images/block-mobile.svg" style={{ height: 20 }} />

            <p
              style={{
                marginLeft: 10,
                fontFamily: "quicksand",
                color: "white",

                cursor: "pointer",
              }}
            >
              {!isBlocked ? "Block" : "Unblock"} {`@${EditorStore.username}`}

            </p>
          </div>
          <div
            onClick={() => {
              if (currentUser == null) {
                // AuthenticationStore.setShowModal(true);
                return;
              }
              WidgetStore.setShowReportModal(true);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <img src="/images/mobile-flag.svg" style={{ height: 20 }} />

            <p
              style={{
                marginLeft: 10,
                fontFamily: "quicksand",
                color: "white",
                cursor: "pointer",
              }}
            >
              Report flow
            </p>
          </div>
          {/* <div
          onClick={() => {
            if (firebase.auth().currentUser == null) {
              AuthenticationStore.setShowModal(true);
              return;
            }
            // document.getElementById(
            //   `flow-options-${props.flowId}`
            // ).style.display = "none";
            // document.getElementById(
            //   `flow-remixes-${props.flowId}`
            // ).style.display = "flex";
            // loadRemixes();
            setLoadRemixes(true);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <div
            style={{
              backgroundImage: "url(/images/Flowroom_Remix_Icon_Green.svg)",
              backgroundSize: "237% 107%",
              height: "35.7px",
              width: "37px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "-28px 0px",
            }}
          ></div>
          <p
            style={{
              marginLeft: 10,
              fontFamily: "quicksand",
              color: "#4FD975",
              cursor: "pointer",
            }}
          >
            Remixes
          </p>
        </div> */}
          <div
            onClick={() => {
              // if (firebase.auth().currentUser == null) {
              //   AuthenticationStore.setShowModal(true);
              //   return;
              // }
              // if (window.innerWidth <= 858) {
              //   WidgetStore.setCurrentlySelectedFeedFlow(props.flowId);
              //   WidgetStore.setShowRemixModal(true);

              //   WidgetStore.setLoadFlowCode(true);

              //   if (
              //     document.getElementById(`flow-options-${props.flowId}`).style
              //       .display == "none"
              //   ) {
              //     document.getElementById(
              //       `flow-options-${props.flowId}`
              //     ).style.display = "flex";
              //   } else {
              //     document.getElementById(
              //       `flow-options-${props.flowId}`
              //     ).style.display = "none";
              //   }
              //   var element = document.getElementById(`${props.flowId}_flow`);

              //   // element.scrollIntoView({
              //   //   behavior: "smooth",
              //   //   block: "start",
              //   //   inline: "nearest",
              //   // });
              // } else {
              //   window.location.href = `/room/${props.flowId}`;
              // }
              WidgetStore.setShowRemixes(true);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <img src="/images/tick-square.svg" style={{ height: 20 }} />

            <p
              style={{
                marginLeft: 10,
                fontFamily: "quicksand",
                color: "white",
                cursor: "pointer",
              }}
            >
              Remixes
            </p>
          </div>
        </div>
      </div>
      <div
        id="collaborator-wrap"
        style={{ position: "absolute", top: 125, height: 500, width: 300 }}
      >
        <CollaboratorsDetails
          flowId={flowId}
        />
      </div>
      {/* <div class="skeleton-loading">
        <div class="skeleton-loading__title"></div>
        <div class="skeleton-loading__subtitle"></div>
      </div> */}
      {/* <RemixesModal /> */}
      <ReportModal />
      <BlockModal
        openModal={WidgetStore.blockModal}
        closeModal={() => {
          WidgetStore.setBlockModal(false);
        }}
        username={EditorStore.username}

      />

      <ReactModal
        isOpen={WidgetStore.tryAgain}
        ariaHideApp={false}
        closeTimeoutMS={500}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#1C1C1C",
            overflow: "auto",
            borderRadius: "15px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% / 1.1)",
            maxWidth: "500px",
            paddingBottom: 20,
            // maxHeight: "370px",
          },
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 25px",
          }}
        >
          <img
            src="/images/tick-square.svg"
            style={{
              height: 120,
              width: 120,
              margin: "auto",
              marginBottom: 10,
              marginTop: 35,
            }}
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              textAlign: "center",
              color: "#FFFFFF",
              opacity: 0.6,
            }}
          >
            You pressed this button too many times. Try again later.
          </p>
          <div
            onClick={() => {
              WidgetStore.setTryAgain(false);
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 50,
              cursor: "pointer",
              maxWidth: 526,
              height: 50,
              border: "1px solid #4FD975",
            }}
          >
            <p
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Close
            </p>
          </div>
        </div>
      </ReactModal>
      <ReactModal
  isOpen={AuthenticationStore.showModal}
  ariaHideApp={false}
  closeTimeoutMS={500}
  style={{
    overlay: {
      padding: 0,
      zIndex: 1000000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(0, 0, 0, 0.8)",
    },
    content: {
      position: "relative",
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
      border: "0px solid rgb(64, 255, 232)",
      backgroundColor: "#3F3F3E",
      overflow: "hidden",
      borderRadius: "15px",
      outline: "none",
      padding: "0px",
      zIndex: 1000000,
      margin: "auto",
      width: "calc(100% / 1.1)",
      maxWidth: 570,
      // maxHeight: WidgetStore.currentModalHeight,
    },
  }}
>
<img src="/images/close-circle.svg"   style={{
                  display: "block",
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor:'pointer'
                }} onClick={()=>{
                  AuthenticationStore.setShowModal(false);
                }}/>
<LoginM />

  </ReactModal>

      <ErrorModal
        openModalError={openModalError}
        closeError={() => {
          setOpenModalError(false);
        }}
      />
      <FeatureRequestModal />
    </div>
  );
});

export default React.memo(DetailsMenu);
