import React, { createContext, useState, useEffect, useContext } from 'react';
import { firebase } from "../firebase/firebase";
import { AuthContext, AuthProvider } from "../../Components/Home/AuthContext";


// Create the context
export const NotificationContext = createContext();

// Provider component
export const NotificationProvider = ({ children }) => {
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const { currentUser } = useContext(AuthContext);

  // useEffect(() => {
  //   if (currentUser) {
  //     const unsubscribe = firebase.firestore()
  //       .collection('notifications')
  //       .where('receiver', '==', currentUser.displayName)
  //       .where('isSeen', '==', false)
  //       .onSnapshot(snapshot => {
  //         setHasNewNotifications(!snapshot.empty);
  //       });

  //     return () => unsubscribe();
  //   }
  // }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      const fetchNotifications = async () => {
        const notificationsRef = firebase.firestore().collection('notifications');
        const followersRef = firebase.firestore().collection('followers');
        
        // Fetch notifications where the current user is the receiver and hasn't seen them yet
        const personalNotificationsQuery = notificationsRef
          .where('receiver', '==', currentUser.displayName)
          .where('isSeen', '==', false);
        
        // Fetch followers of the current user
        const followersSnapshot = await followersRef
          .where('followerUserId', '==', currentUser.uid)
          .get();
        
        const followedUserIds = followersSnapshot.docs.map(doc => doc.data().userId);
        
        // Fetch notifications from followed users with a specific type
        const followedUserNotificationsPromises = followedUserIds.map(userId =>
          notificationsRef
            .where('userId', '==', userId)
            .where('type', '==', 'postedFlow')
            .where('isSeen', '==', false)
            .get()
        );
        
        // Wait for all promises to resolve
        const results = await Promise.all([personalNotificationsQuery.get(), ...followedUserNotificationsPromises]);
        
        // First result is the current user's notifications, rest are followed users' notifications
        const personalNotifications = results[0].docs.map(doc => ({ ...doc.data(), id: doc.id }));
        const followedUserNotifications = results.slice(1).flatMap(result => result.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        
        // Combine and deduplicate notifications
        const allNotifications = [...personalNotifications, ...followedUserNotifications];
        const uniqueNotifications = [...new Map(allNotifications.map(notification => [notification.id, notification])).values()];
        
        // Update state based on whether there are unseen notifications
        setHasNewNotifications(uniqueNotifications.length > 0);
      };
      
      fetchNotifications().catch(console.error);
  
      // Since this useEffect does not set up a real-time listener, there's no unsubscribe function to return
    }
  }, [currentUser]);
  
  

  return (
    <NotificationContext.Provider value={{ hasNewNotifications, setHasNewNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Hook to use the notification context
export const useNotifications = () => useContext(NotificationContext);
