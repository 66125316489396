import React from "react";
import Comment from "./NewComment.tsx";

export default function NewComments({
  comments,
  flowId,
  isLoaded,
  myUserId,
}: {
  comments: any[];
  flowId: string;
  isLoaded: boolean;
  myUserId: string;
}) {
  if (!isLoaded || !comments) {
    return <div></div>;
  } else {
    return (
      <Comment
        key={flowId + "_comment_" + Math.random()}
        comments={comments}
        flowId={flowId}
        myUserId={myUserId}
      />
    );
  }
}
