// libraries
import { observer } from "mobx-react-lite";
import { default as React, useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-crop/dist/ReactCrop.css";
import { collection } from "typesaurus";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
// store
import { CommentsStoreContext } from "../../store/CommentsStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { ProfileStoreContext } from "../../store/ProfileStore.js";
import { PublishMenuStoreContext } from "../../store/Publish_Menu.js";
import { RemixableStoreContext } from "../../store/RemixableStore";
import { RouteStoreContext } from "../../store/RouteStore.js";
import { ShareStoreContext } from "../../store/ShareStore.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { firebase } from "../firebase/firebase";
// content
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { RoomContext } from "../Home/RoomContext.js"; // Make sure to import the correct context
import FlowCard from "./Feed/FlowCard.js";
import { Config } from "./config/Config";

let likesCollection = collection("likes");
let flowsCollection = collection("flows");

let tagsLengthArray = [];
let tagCounter = 0;
let timer = null;
let flowCounter = 0;
let frameLoaded;
let remixesArr = [];
let lastIndexFlow;
let firestore = firebase.firestore();
let roomsPerPage = window.innerWidth >= 1024 ? 6 : 4;
let urlHTML;
let urlCSS;
let urlJS;

const RoomPost = observer(({ position }) => {
  let [pending, setPending] = useState(false);
  const [progress, setProgress] = useState(0);
  const [theHeight, setTheHeight] = useState("29vw");
  const [isFull, setIsFull] = useState(false);
  const [fullscreen, setFullcreen] = useState(false);
  const [pHeight, setPHeight] = useState("");
  const [dElem, setDElem] = useState("");
  const [tags, setTags] = useState([]);
  const [tagColor, setTagColor] = useState("");
  let [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState(0);
  const [commentsCountIndex, setCommentsCountIndex] = useState(0);
  const [isShowFrame, setIsShowFrame] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  // const [thumbnail, setThumbnail] = useState(props.thumbnail);
  const [showExpand, setShowExpand] = useState(false);
  const [hideExpand, setHideExpand] = useState(false);
  const CommentsStore = useContext(CommentsStoreContext);
  const RouteStore = useContext(RouteStoreContext);
  const ShareStore = useContext(ShareStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  let currentUser = firebase.auth().currentUser;
  //console.log("[RoomPost] currentUser:", currentUser);
  const RemixableStore = useContext(RemixableStoreContext);
  const [creditPlus, setCreditPlus] = useState(true);
  const FlowStore = useContext(FlowStoreContext);
  const [showShare, setShowShare] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [hasMoreRemixes, setHasMoreRemixes] = useState(false);
  const [loadFlowCode, setLoadFlowCode] = useState(false);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [loadRemixes, setLoadRemixes] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [showPaidSubscription, setShowPaidSubscription] = useState(false);
  const [showPaidFlow, setShowPaidFlow] = useState(false);
  const [showPaidFree, setPaidFree] = useState(false);
  const [showNSFW, setShowNSFW] = useState(false);
  const [showBrowserNotCompatible, setShowBrowserNotCompatible] =
    useState(false);
  const [showDeviceNotCompatible, setShowDeviceNotCompatible] = useState(false);
  const [showOptionsComponent, setShowOptionsComponent] = useState(false);
  const [showBrowserCompatibility, setShowBrowserCompatibility] =
    useState(false);
  const [showRemixPosted, setRemixPosted] = useState(false);

  const [showRemixesWrap, setShowRemixesWrap] = useState(false);
  const [showDetailsOverlay, setShowDetailsOverlay] = useState(false);
  const [showPinnedFlow, setShowPinnedFlow] = useState(false);
  const [skeletonHidden, setSkeletonHidden] = useState(true);
  const [showWidgetRemix, setShowWidgetRemix] = useState(false);
  const [showPostFeed, setShowPostFeed] = useState(false);
  const [showSaveFeed, setShowSaveFeed] = useState(false);

  let ProfileStore = useContext(ProfileStoreContext);
  let EditorStore = useContext(EditorStoreContext);

  const alignment = position === "left" ? "flex-start" : "flex-end";

  const room = useContext(RoomContext);

  console.log("room", room);

  const RoomPlaceholder = () => {
    return (
      <div className="room-placeholder">
        {/* Your placeholder content, e.g., gray boxes, shimmer effects, etc. */}
        <div
          style={{
            height: 657,
            width: "100%",
            background: "rgb(28, 28, 28)",
            boxShadow: "rgb(0, 0, 0) 0px 0px 17px -7px",
            borderRadius: 20,
            overflow: "hidden",
            maxWidth: 503,
            margin: "auto",
          }}
          className="skeleton-card"
        ></div>
        <style>
          {`
      .skeleton-card {
        overflow: hidden;
        position: relative;
        background: rgb(28, 28, 28);
        box-shadow: rgb(0, 0, 0) 0px 0px 17px -7px;
        border-radius: 20px;
        height: 782px;
        width: 100%;
      }
  
      .skeleton-card:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background:rgb(28, 28, 28);
        transform: translateX(-100%);
        animation: shimmer 2s infinite;
        overflow:hidden;
        borderRadius:20px;
      }
  
      @keyframes shimmer {
        0% { transform: translateX(-100%); }
        100% { transform: translateX(100%); }
      }
    `}
        </style>
      </div>
    );
  };

  // const isDeviceCompatible = () => {
  //   if (
  //     props.deviceMobile === true &&
  //     props.deviceTablet === true &&
  //     props.deviceDesktop === true &&
  //     (props.isCurrentlyDesktopResolution === true ||
  //       props.isCurrentlyMobileTabletResolution === true)
  //   ) {
  //     return false;
  //   }

  //   if (
  //     (props.deviceMobile === true &&
  //       props.isCurrentlyDesktopResolution == true) ||
  //     (props.isCurrentlyMobileTabletResolution == true &&
  //       props.deviceDesktop === true)
  //   ) {
  //     return true; // return true as in show device is not compatible
  //   }

  //   if (
  //     props.deviceMobile === true &&
  //     props.deviceTablet === true &&
  //     props.isCurrentlyDesktopResolution === true
  //   ) {
  //     return true;
  //   }
  // };

  // useEffect(() => {
  //   // setLoadRemixes(true);
  //   // console.log("recent", props.isChrome + " ");
  //   console.log("room post provider", room);

  //   console.log("urlHTML", room.urlHTML);
  //   console.log("urlCSS", room.urlCSS);
  //   console.log("urlJS", room.urlJS);

  //   let userAgent = navigator.userAgent;
  //   let browserName;
  //   console.log("browsers", room.browserTags);
  //   if (userAgent.match(/chrome|chromium|crios/i)) {
  //     browserName = "chrome";
  //   } else if (userAgent.match(/firefox|fxios/i)) {
  //     browserName = "firefox";
  //   } else if (userAgent.match(/safari/i)) {
  //     browserName = "safari";
  //   } else if (userAgent.match(/opr\//i)) {
  //     browserName = "opera";
  //   } else if (userAgent.match(/edg/i)) {
  //     browserName = "edge";
  //   } else {
  //     browserName = "No browser detection";
  //   }
  //   if (
  //     (room.isChrome !== undefined &&
  //       browserName === "chrome" &&
  //       room.isChrome == false) ||
  //     (room.isFirefox !== undefined &&
  //       browserName === "firefox" &&
  //       room.isFirefox == false) ||
  //     (room.isSafari !== undefined &&
  //       browserName === "safari" &&
  //       room.isSafari == false) ||
  //     (room.isOpera !== undefined &&
  //       browserName === "opera" &&
  //       room.isOpera == false) ||
  //     (room.isBrave !== undefined &&
  //       browserName === "brave" &&
  //       room.isBrave == false) ||
  //     (room.isEdge !== undefined &&
  //       browserName == "edge" &&
  //       room.isEdge == false)
  //   ) {
  //     setShowBrowserCompatibility(true);
  //   }
  //   return () => {
  //     // Clean up or cancel any ongoing tasks or subscriptions here
  //   };
  // }, []);

  // let modalMenu = MoreModalContainer.useContainer();
  // let thumbnailCover = room.thumbnail;
  let timerThumbnail;
  let timerCount = 0;
  let timerCountLimit = 10;
  console.log("props remixed", room);
  let stringToHTML = function (str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, "text/html");
    return doc.body;
  };



  useEffect(() => {
    //console.log("[RoomPost] useEffect flowId:", props.flowId);
    // init();
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  // TODO: fix increment comments count when posting new comment

  useEffect(() => {
    if (CommentsStore.flowId == room.flowId) {
      if (CommentsStore.incrementCommentsCount === true) {
        setComments(comments + 1);
        CommentsStore.setIncrementCommentsCount(false);
      }
      if (CommentsStore.decrementCommentsCount === true) {
        setComments(comments - 1);
        CommentsStore.setDecrementCommentsCount(false);
      }
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [CommentsStore.commentsCount]);

  const loadComments = async () => {
    let commentsArr = [];
    const snapshotUsers = await firebase
      .firestore()
      .collection("comments")
      .where("flowId", "==", room.flowId)
      .get();

    snapshotUsers.forEach((doc) => {
      let data = doc.data();
      //console.log("[RoomPosts] loadRooms user:", data);
      if (data.comment && data.comment != "") {
        commentsArr.push(data.comment);
      }
    });

    setComments(commentsArr);
  };

  const init = () => {
    //console.log("[RoomPost] init props:", props);

    loadComments();
    setLikes(room.likes);
    setComments(room.comments);
    // setThumbnail(room.thumbnail);
    setProfilePic(room.urlPhoto);
    setIsLiked(room.isLiked);
    // initThumbnail(room.thumbnail);
    setIsPinned(room.isPinned);
  };

  const createRoom = (roomData) => {
    //Important to get your list of followers and share with them in their feed and your profile

    //post

    PublishMenuStore.startCreateRoom(roomData);
    // FlowStore.setFlowId(flowId);
    // FlowStore.setRoom(roomData);
    // FlowStore.setIsFlowOwner(true);
  };

  const expandText = () => {
    var pos = 0;
    var id = setInterval(frame, 0);
    function frame() {
      if (pos == pHeight) {
        clearInterval(id);
      } else {
        pos++;
        dElem.style.height = pos + "px";
      }
    }
  };

  // share


  // render

  return (
    <div
      id={`room_${room.flowId}`}
      className="room-post"
      style={{
        width: "100%",
        //props.roomWidth,
        // marginTop: 30,
        position: "relative",
        boxShadow: "rgb(0, 0, 0) 0px 0px 17px -7px",
        // maxHeight: "calc(100% - 30px)",
        borderRadius: "20px",
        overflow: "hidden",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          background: "#F9F9F9",
          overflow: "hidden",
          position: "relative",
          WebkitBoxSizing: "border-box",
          MozBoxSizing: "border-box",
          boxSizing: "border-box",
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          visibility: skeletonHidden === false ? "hidden" : "visible",
        }}
      >
         {/* <RoomPlaceholder/> */}
                  {/* <Link to={`/room/${room.flowId}`}> */}

        <FlowCard />
        {/* </Link> */}
      </div>
      <div
        style={{
          position: "absolute",
          height: 700,
          width: "100%",
          maxWidth: 443,
          zIndex: 10000,
          top: 35,
          visibility: skeletonHidden === false ? "visible" : "hidden",
        }}
      >
        <Stack spacing={2}>
          {/* <Skeleton variant="text" style={{ backgroundColor: "#3F3F3E" }} /> */}
          <Skeleton
            variant="circular"
            width={37}
            height={37}
            color={"#3F3F3E"}
            style={{
              backgroundColor: "#3E3E3E",
              marginBottom: 7,
              marginTop: 20,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={() =>
              document.getElementById(`room_${room.flowId}`).offsetWidth + "px"
            }
            color={"#3F3F3E"}
            style={{
              backgroundColor: "#3F3F3E",
              height: 635,
              width: "100%",
              maxWidth: 443,
              borderRadius: 20,
            }}
          />
        </Stack>
      </div>
    </div>
  );
});
const areEqual = (prevProps, nextProps) => true;
// RoomPost.whyDidYouRender = true;

export default React.memo(RoomPost);
