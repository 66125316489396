import React, { useState, useEffect, useContext } from 'react';
import styles from './SidebarMenu.module.css'; // Import as a module
import { CSSTransition } from 'react-transition-group'; // Make sure to install react-transition-group for animations
import InfoComponent from "../Home/InfoComponent";
import ControlWidget from './ControlWidget';
import { EditorStoreContext } from "../../store/EditorStore.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore";

import { CommentsStoreContext } from '../../store/CommentsStore';
import { TabStoreContext } from '../../store/TabStore';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, Navigate  } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { AuthContext } from "../Home/AuthContext.js"; 
import { firebase } from "../firebase/firebase.js";
import RateLimit from "../Home/RateLimit.js";
import ReactModal from 'react-modal';
const Tab = ({ isSelected, iconSelected, iconUnselected, onClick, likes, comments }) => {
  // Determine if the icon is a heart or comments icon and if there's a need to show likes/comments
  const shouldShowLikesOrComments = (() => {
    if (iconSelected.includes('heart-icon-menu-selected') || iconUnselected.includes('heart-menu')) {
      return likes > 0 ? likes : null;
    } else if (iconSelected.includes('comments-selected') || iconUnselected.includes('comments-menu')) {
      return comments > 0 ? comments : null;
    }
    return null;
  })();

  // Check if the icon is a heart icon
  const isHeartIcon = isSelected
    ? iconSelected.includes('heart-icon-menu-selected')
    : iconUnselected.includes('heart-menu');

  return (
    <div style={{ display: 'flex', flexDirection: 'column',
    padding: 7,
    position: "relative" }} className={`${styles.tab} ${isSelected ? styles.selected : ''}`} onClick={onClick}>
      <img
        src={isSelected ? iconSelected : iconUnselected}
        alt=""
        style={{
          height: isHeartIcon && isSelected ? 20 : 24,
          width: isHeartIcon && isSelected ? 20 : 24,
        }}
      />
      {/* Conditionally render likes/comments number if greater than 0, and adjust color based on selection */}
      {shouldShowLikesOrComments !== null && (
        <p style={{
          fontFamily: 'quicksand',
          fontSize: 11,
          color: isSelected ? '#4FD975' : 'white' // Change color when selected
        }}>
          {shouldShowLikesOrComments}
        </p>
      )}
    </div>
  );
};





const Spinner = () => (<div style={{height:'100vh', width:'100%', backgroundColor:'#1c1c1c', display:'flex', justifyContent:'center', alignItems:'center'}}>
  <div className={styles.spinner}>
    <div className={styles.spinnerInner}></div>
  </div>
  </div>
);
// Placeholder components for demonstration
const PlaceholderComponentA = () => <div style={{ color: 'white' }}></div>;
const PlaceholderComponentB = () => <div style={{ color: 'white' }}></div>;
const PlaceholderComponentC = () => <div style={{ color: 'white' }}></div>;
// Add more placeholders as necessary

const SidebarMenu = observer(({ isPanelOpen, setIsPanelOpen }) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
const [activeModal, setActiveModal] = useState(null); // If handling multiple modals
const [activeModalId, setActiveModalId] = useState(null); // To identify which modal to show
const [deniedCodeEditor, setDeniedCodeEditor] = useState(false);
const [pending, setPending] = useState(false);
const [openModalRateLimit, setOpenModalRateLimit] = useState(false);
const [lastClickTime, setLastClickTime] = useState(0);
const [clickCount, setClickCount] = useState(0);

const EditorStore = useContext(EditorStoreContext);
const FlowStore = useContext(FlowStoreContext);
const CommentsStore = useContext(CommentsStoreContext);
const AuthenticationStore = useContext(AuthenticationStoreContext);
const WidgetStore = useContext(WidgetStoreContext)
const { currentUser } = useContext(AuthContext);

const TabStore = useContext(TabStoreContext);
const { id } = useParams();

const addLikeNotification = async (
  sender,
  receiver,
  commentContent,
  photoURL
) => {
  const newNotification = {
    sender: currentUser.displayName,
    receiver: EditorStore.username,
    type: "likedFlow",
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    additionalData: {
      commentContent: commentContent,
      photoURL: photoURL,
    },
    flowId:EditorStore.flowId,
    isSeen:false
  };

  await firebase.firestore().collection("notifications").add(newNotification);
};
const useViewportWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (newWidth > 758) { // Only update width if greater than 758px
        setWidth(newWidth);
      }
    };

    const debouncedHandleResize = debounce(handleResize, 100); // Debounce resize events by 100ms

    window.addEventListener('resize', debouncedHandleResize);

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  return width;
};


const incrementLikes = async () => {
  if (currentUser == null || currentUser.displayName == null) {
    console.log("User not authenticated.");
    return;
  }

  const currentTime = Date.now();
  // Reset the counter if more than 3 seconds have passed since the last recorded click
  if (currentTime - lastClickTime > 3000) {
    setClickCount(0);
  }

  // Increment click count and update last click time
  setClickCount(prevCount => prevCount + 1);
  setLastClickTime(currentTime);

  // Check if the click count exceeds the allowed number of rapid clicks
  if (clickCount > 20) { // Adjust number as per your requirements
    console.log("Too many likes. Try again later.");
    WidgetStore.setErrorModalMessage("You clicked too many times. Please wait a few seconds and try again.");
    setOpenModalRateLimit(true);
    return;
  }

  addLikeNotification(
    currentUser.displayName,
    EditorStore.username,
    "",
    currentUser.photoURL
  );

  setPending(true);
  const docLike = await firebase
    .firestore()
    .collection("likes")
    .doc(currentUser.uid + ":" + EditorStore.flowId)
    .get();

  if (docLike.exists) {
    await firebase
      .firestore()
      .collection("likes")
      .doc(currentUser.uid + ":" + EditorStore.flowId)
      .delete();
    setPending(false);
  } else {
    await firebase
      .firestore()
      .collection("likes")
      .doc(currentUser.uid + ":" + EditorStore.flowId)
      .set({
        flowId: EditorStore.flowId,
        likeId: currentUser.uid + ":" + EditorStore.flowId,
        userId: currentUser.uid,
        timestamp: new Date().toISOString(),
        username: currentUser.displayName
      }, { merge: true });
    setPending(false);
  }
};







// Helper function to debounce another function
function debounce(func, delay) {
  let timer;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

useEffect(()=>{
  if (selectedTab !== null) {
  const tab = tabs[selectedTab];
  setIsPanelOpen(false);


  }
},[isPanelOpen])


useEffect(() => {
  // Ensure there's a selected tab and retrieve its configuration
  if (selectedTab !== null) {
    const tab = tabs[selectedTab];
    // Decide whether to show or hide the panel based on the tab's action
  
    if (tab.action === 'panel') {
      setIsPanelOpen(true);
    } else {
      setIsPanelOpen(false);
      // For modal actions or others that don't require the panel, you could trigger additional logic here
      if (tab.action === 'modal') {
        console.log("Opening modal for:", tab.iconSelected); // Placeholder for your modal logic
        // Trigger the modal associated with the tab here
        openModal(tab.iconSelected); // Make sure to implement this function based on your modal handling logic
      }
    }
  }
}, [isPanelOpen]); // Listen for changes in selectedTab



const openModal = (modalId) => {
  setActiveModalId(modalId); // Set which modal is active
  setIsModalOpen(true); // Show the modal
};

// Function to close the modal and reset state
const closeModal = () => {
  setIsModalOpen(false);
  
  // setActiveModalId(null);
};


const handleTabClick = (index) => {
  const tab = tabs[index];

  // Update the selected tab state
  setSelectedTab(index);
  TabStore.setSelectedTab(index);
  // Directly set the panel's visibility based on the tab's action

  if (tab.action === 'panel') {
    if (!isPanelOpen) {
      setIsPanelOpen(true);
      
    } else {
      setIsPanelOpen(false);

    }
  } else {
    // For actions other than 'panel', close the panel if it's open
    // This includes 'modal' actions or any other defined action that doesn't require the panel
    if (isPanelOpen) {
      setIsPanelOpen(false);

    }

   

    if(tab.action === "share") {
      EditorStore.setShowShareModal(true);
    } 

    if(tab.action === 'comments') {
      CommentsStore.setIsShowModal(true)
    }

    if(tab.action === 'like') {
      incrementLikes();
    }

    if(tab.action === 'ai') {
      if (currentUser == null) {
        AuthenticationStore.setShowModal(true);
        return;
      }
      if (
        currentUser.displayName != null &&
        currentUser.displayName != ""
      ) {
        EditorStore.setShowCreateAI(true);

      } else {
        AuthenticationStore.setShowModal(true);
      }
    }

    if(tab.action === 'guide') {
      WidgetStore.setShowCreateGuide(true);
    }


    if(tab.action === 'save') {
      
        if (currentUser == null) {
          AuthenticationStore.setShowModal(true);
          return;
        }
        if (
          currentUser.displayName != null &&
          currentUser.displayName != ""
        ) {
          EditorStore.setShowPostModal(true);

        } else {
          AuthenticationStore.setShowModal(true);
        }
      
    }

    if(tab.action === 'more') {
      EditorStore.setShowMoreModal(true)
    }
    
    if (tab.action === 'modal') {
      console.log("Modal should open for:", tab.iconSelected);
      
      // Assuming openModal is implemented elsewhere to manage modal visibility
      openModal(tab.iconSelected);
    }

    if(tab.action === 'code') {
      const executeCode = () => {
          // EditorStore.showAnimationF();
          document.getElementsByClassName("top-box-wrap")[0].style.display = "block";
          EditorStore.showAnimationF();
          let previewBar = document.getElementById('preview-bar');
          if(previewBar.style.display === 'block') {
              previewBar.style.display = 'none';
          } else {
              previewBar.style.display = 'block';
          }
      };
  
      const showDeniedModal = () => {
          // EditorStore.setDeniedCodeEditor(true);
          setDeniedCodeEditor(true);
      };
  
      if(FlowStore.isFlowOwner === true || id === "new") {
          executeCode();
      } else {
          // Query the Firestore database
          firebase.firestore().collection("notifications")
              .where("isAccepted", "==", true)
              .where("type", "==", "collabRequest")
              .where("flowId", "==", id) // assuming flowId is a variable containing the id of the flow
              .get()
              .then(querySnapshot => {
                  let hasAccess = false;
                  querySnapshot.forEach(doc => {
                      if(doc.data().receiver === currentUser.displayName) { // checking if the receiver matches the currentUser
                          hasAccess = true;
                      }
                  });
                  if(hasAccess) {
                      executeCode();
                  } else {
                      showDeniedModal();
                  }
              })
              .catch(error => {
                  console.error("Error fetching notifications: ", error);
                  showDeniedModal();
              });
      }
  }
  

  }
};


const closePanel = () => {
  setIsPanelOpen(false); // Only close the panel
  // setSelectedTab(null); // Consider removing or managing this elsewhere based on your app's needs

};


const tabs = [
  ...(id === "new" ? [
    // This is the special tab that should only appear when id is "new"
    { iconSelected: '/images/create-robot-selected.svg', iconUnselected: '/images/create-robot.svg', action: 'ai' },
  ] : [
    // These are the default tabs when id is not "new"
    { iconSelected: '/images/info-circle.svg', iconUnselected: '/images/info-menu.svg', action: 'panel', component: InfoComponent },
    { iconSelected: '/images/heart-icon-menu-selected.svg', iconUnselected: '/images/heart-menu.svg', action: 'like', modalComponent: PlaceholderComponentB },
    { iconSelected: '/images/comments-selected.svg', iconUnselected: '/images/comments-menu.svg', action:'comments', component: PlaceholderComponentC },
    { iconSelected: '/images/share-selected.svg', iconUnselected: '/images/share-flow.svg', action:'share' },
  ]),
  // These tabs appear regardless of the id value
  { iconSelected: '/images/widget-menu-selected.svg', iconUnselected: '/images/widget-menu.svg', action: 'panel', component: ControlWidget },
  { iconSelected: '/images/code-editor-menu-selected.svg', iconUnselected: '/images/code-editor-menu.svg', action: 'code' },

  { iconSelected: '/images/remix-guide-selected.svg', iconUnselected: '/images/remix-guide.svg', action: 'guide' },
  { iconSelected: '/images/save-desktop-selected.svg', iconUnselected: '/images/save-desktop.svg', action: 'save' },
  { iconSelected: '/images/more-tabbar-mobile-selected.svg', iconUnselected: '/images/more-tabbar-mobile.svg', action: 'more' },

  { iconSelected: '', iconUnselected: '', action: 'close' },

  // Add other tabs as necessary
];



  useEffect(() => {
    if (selectedTab !== null) {
      setLoading(true);
      setIsPanelOpen(true);
      setTimeout(() => setLoading(false), 500); // Simulate loading time
    }
  }, [selectedTab, setIsPanelOpen]);

  // const viewportWidth = useViewportWidth();
  // const shouldRenderMenu = viewportWidth >= 768;

  // useEffect(() => {
  //   if (!shouldRenderMenu) {
  //     // Perform any side effects here if necessary when the menu shouldn't render
  //   } else {
  //     // Side effects for when the menu should render
  //   }
  // }, [shouldRenderMenu]); // Depend on shouldRenderMenu to re-run the effect when it changes

  // if (!shouldRenderMenu) {
  //   return null; // This early return is fine since all hooks are called above
  // }

  useEffect(()=>{
    setIsPanelOpen(false);
    TabStore.setClosePanel(false);
    setSelectedTab(null)
   },[TabStore.closePanel]) 

  const renderContent = () => {
    const contentClass = loading ? styles.slidePanelContent + " " + styles.loading : styles.slidePanelContent;
  
    return (
      <div className={contentClass}>
   
        {loading ? <Spinner /> : null}
        {!loading && tabs[selectedTab]?.component ? React.createElement(tabs[selectedTab].component) : null}
      </div>
    );
  };
  return (
    <div className={styles.container}>
      
      <div className={styles.sidebarMenu}>
        {tabs.map((tab, index) => {
          return(
       <Tab
       key={index}
       isSelected={selectedTab === index}
       iconSelected={tab.iconSelected}
       iconUnselected={tab.iconUnselected}
       onClick={() => handleTabClick(index)}
       likes={EditorStore.likes}
       comments={EditorStore.comments}
     />)
          }
          )}
      </div>
      <CSSTransition
        in={isPanelOpen}
        timeout={300}
        classNames={{
          enter: styles['panel-enter'],
          enterActive: styles['panel-enter-active'],
          exit: styles['panel-exit'],
          exitActive: styles['panel-exit-active']
        }}
        unmountOnExit
      >
        <div className={styles.slidePanel}>
        {/* <img src="../images/close-circle.svg" onClick={() => {handleTabClick(7); setSelectedTab(null)}}
               style={{ height: 20, width: 20, cursor: "pointer", position: "absolute", right: 7, top: 30, zIndex: 1000 }} /> */}
          {renderContent()}
        </div>
      </CSSTransition>
      <ReactModal
      isOpen={deniedCodeEditor}
      ariaHideApp={false}
      closeTimeoutMS={500}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.2)",
        },
        content: {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#1C1C1C",
          overflow: "auto",
          borderRadius: "15px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% / 1.1)",
          height: "344px",
          maxWidth: "557px",
          padding: 20,
          overflowX:'hidden'
        },
      }}
    >
      <div style={{ display: "flex", justifyContent:'center', alignItems:'center', flexDirection:'column' }}>
      <div onClick={()=>{
         setDeniedCodeEditor(false);
                                  
                   }} style={{ 
                         display: "block",
                         position: "absolute",
                         right: "10px",
                         top: "10px",
                         cursor: "pointer",
                         backgroundImage:'url(../images/close-circle.svg)', backgroundSize:'contain', height:20, width:20, marginTop:15, marginRight:10}}></div>
      <img src="../images/warning.svg" style={{marginTop:40, height:70}}/>
      <p style={{color:'white', fontFamily:'quicksand', fontSize:15,
    marginTop: 30,
    textAlign: "center",
    maxWidth: 450, marginBottom:20}}>You can not get access to code unless you created it or are made a collaborator by the owner.</p>
<div style={{height:50, width:100, backgroundColor:'#4FD975', height: 40,
    width: 200,
    backgroundColor: 'rgb(79, 217, 117)',
    borderRadius: 10, cursor:'pointer', marginTop:15, justifyContent:'center', alignItems:'center', display:'flex'}} onClick={()=>{
      setDeniedCodeEditor(false);

    }}><p style={{color:'#000'}}>OK</p></div>
  <style jsx>{`
    .button-container {
      width: 48%; /* default width */
      height: 42px;
      border: 1px solid #4FD975;
      border-radius: 10px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      /* other styles */
    }

    @media (max-width: 600px) { /* Adjust this breakpoint as needed */
      .button-container {
        width: 100%; /* full width on small screens */
      }
    }

  .dropdown-container {
    position: relative;
  }
  .dropdown-display {
    background-color: #1c1c1c !important;
    padding: 7px !important;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #4fd975;
    color: white;
    font-family: "Quicksand";
    overflow: hidden;
    border-radius: 20px;
  }
  .dropdown-list {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #1c1c1c;
    border: 0px solid #ddd;
    border-radius: 5px;
    width: 100%;
    z-index: 100;
    bottom: -59px !important;
    border-left: 1px solid #4fd975 !important;
    border-bottom: 1px solid #4fd975 !important;
    border-right: 1px solid #4fd975 !important;
    color: white;
    font-family: quicksand;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow-y: auto; /* Enable vertical scrolling if needed */
    max-height: 200px; /* Adjust based on your requirement */

    /* Custom scroll bar styles */
    scrollbar-width: thin;
    scrollbar-color: #4fd975 #1c1c1c; /* thumb and track color */

    /* For Webkit browsers */
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #1c1c1c;
    }
    &::-webkit-scrollbar-thumb {
      background: #4fd975;
      border-radius: 4px;
    }
  }
  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
  }
  `}</style>
      </div>
    </ReactModal>
    <RateLimit
        
        openModalRateLimit={openModalRateLimit}
        closeError={() => {
          setOpenModalRateLimit(false);
        }}
        />
    </div>
  );
});

export default React.memo(SidebarMenu);
