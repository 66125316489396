import { faCheck, faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import firebase from "firebase";
import { createBrowserHistory } from "history";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactImageFallback from "react-image-fallback";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { RouteStoreContext } from "../../store/RouteStore.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";

import { AuthContext } from "./AuthContext";

import Tooltip from "../Home/Tooltip/Tooltip.js";
import "../styles/stylesheet.css";

import { useNotifications } from '../../Components/Home/NotificationContext.js'; // Adjust the import path as necessary


const LoggedOutSignedIn = React.lazy(() => import("./LoggedOutSignedIn.js"));

const LoginM = React.lazy(() => import("./LoginM.js"));

const SignInM = React.lazy(() => import("./SigninM.js"));

let drawerWidth = "100%";




let localUserString = localStorage.getItem("flowroom-user");
let localUser =
  localUserString && localUserString != "[object Object]"
    ? localUserString
    : "";

let currentUserString =
  JSON.parse(localStorage.getItem("flowroom-user")) !== null &&
  JSON.parse(localStorage.getItem("flowroom-user")) !== undefined
    ? JSON.parse(localStorage.getItem("flowroom-user")).displayName
    : "";
    function formatNumber(number) {
      // Check if the number is negative and handle it
      if (number < 0) {
        return "0"; // Converts any negative number to "0"
      }
    
      if (number < 1000) {
        return number.toString();
      } else if (number >= 1000 && number < 1000000) {
        return (number / 1000).toFixed(number % 1000 !== 0 ? 1 : 0) + 'k';
      } else if (number >= 1000000 && number < 1000000000) {
        return (number / 1000000).toFixed(number % 1000000 !== 0 ? 1 : 0) + 'M';
      } else if (number >= 1000000000) {
        return (number / 1000000000).toFixed(number % 1000000000 !== 0 ? 1 : 0) + 'B';
      }
    }
    
// const useStyles = makeStyles((theme) => ({
//   palette: {
//     primary: {
//       main: "#000",
//     },
//     secondary: {
//       main: "#000",
//     },
//   },
//   grow: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     color: "white",
//   },
//   button: {
//     marginLeft: 12,
//   },
//   drawer: {
//     width: drawerWidth,
//     maxWidth: "600px",
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//     maxWidth: "600px",
//   },
//   drawerHeader: {
//     display: "flex",
//     alignItems: "center",
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: "flex-end",
//   },
// }));

const history = createBrowserHistory({ forceRefresh: true });

const Header = observer((props) => {
  const { currentUser } = useContext(AuthContext);

  const matches = useMediaQuery("(max-width:570px)");
  // const classes = useStyles();

  let [open, setOpen] = useState(false);

  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const RouteStore = useContext(RouteStoreContext);

  const [avatarHeight, setAvatarHeight] = useState(32);
  const [avatarWidth, setAvatarWidth] = useState(32);
  const [mailNotification, setMailNotification] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [toggleWalkthrough, setToggleWalkthrough] = useState(false);
  const [notSignedInMessageSeen, setNotSignedInMessageSeen] = useState(false);
  const FlowStore = useContext(FlowStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const localData = JSON.parse(localStorage.getItem("flowroom-user"));
  const EditorStore = useContext(EditorStoreContext);
  const { hasNewNotifications } = useNotifications();

  const loggedOutStyle = {
    color: "white",
    height: "64px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "0",
    zIndex: "100",
    cursor: "pointer",
    // Add any other styles you want
  };

  const loginBtnStyle = {
    borderRadius: 20,
    border: "0px solid rgb(64, 255, 232)",
    fontSize: 12,
    color: "rgb(255, 255, 255)",
    fontWeight: 700,
    outline: "none",
    cursor: "pointer",
    fontFamily: "quicksand",
    padding: "0px",
    height: "33px",
    background: "transparent",
    marginRight: 10,
  };

  useEffect(() => {
    if (!currentUser) {
      const headerIcons = document.querySelector(".header-icons");
      const loginBtn = document.querySelector("#login-btn");

      if (currentUser == null) {
        // Assuming currentUser is null when logged out
        Object.assign(headerIcons.style, loggedOutStyle);
        // Object.assign(loginBtn.style, loginBtnStyle);
      }
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [currentUser]);

  // useEffect(() => {
  //   let notSignedMSG = localStorage.getItem("notSignedInMessageSeen");
  //   if (notSignedMSG !== null && notSignedMSG === "true") {
  //     setNotSignedInMessageSeen(true);
  //   }
  //   return () => {
  //     // Clean up or cancel any ongoing tasks or subscriptions here
  //   };
  // }, []);

  // useEffect(() => {
  //   const currentUser = firebase.auth().currentUser;
  //   const notificationsRef = firebase.firestore().collection('notifications');
  //   const query = notificationsRef.where('receiver', '==', currentUser.displayName).where('isSeen', '==', false);
  
  //   const unsubscribe = query.onSnapshot(snapshot => {
  //     const hasUnseen = !snapshot.empty;
  //     setHasNewNotifications(hasUnseen);
  //   });
  
  //   return () => unsubscribe(); // Cleanup the listener on component unmount
  // }, []);
  

  useEffect(() => {
    if (currentUser !== null) {
      firebase
        .firestore()
        .collection("credits")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return;
          }
          let data = doc.data();
          FlowStore.setCredits(data.credits);
        });
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  },[]);

  // useState(() => {}, [RouteStore.lastPage]);

  // useEffect(() => {
  //   // localStorage.clear();
  //   function mediaQuery(x) {
  //     if (x.matches) {
  //       // If media query matches
  //       // setAvatarHeight(25);
  //       // setAvatarWidth(25);
  //     } else {
  //       // setAvatarHeight(32);
  //       // setAvatarWidth(32);
  //     }
  //   }
  //   var x = window.matchMedia("(max-width: 570px)");
  //   mediaQuery(x); // Call listener function at run time
  //   x.addListener(mediaQuery); // Attach listener function on state changes
  // }, []);

  // const pageIcon = () => {
  //   if (
  //     RouteStore.lastPage !== "" &&
  //     window.location.pathname.includes("room") == true
  //   ) {
  //     return (
  //       <FontAwesomeIcon
  //         onClick={() => {
  //           window.history.back();
  //         }}
  //         icon={faChevronLeft}
  //         color="#4FD975"
  //         size={"4x"}
  //         style={{ margin: 5 }}
  //       />
  //     );
  //   } else if (
  //     RouteStore.lastPage === "" &&
  //     window.location.pathname.includes("create") == true
  //   ) {
  //     return (
  //       <Link to="/">
  //         <FontAwesomeIcon
  //           icon={faHome}
  //           color="#A962F1"
  //           size={"4x"}
  //           style={{ margin: 5 }}
  //         />
  //       </Link>
  //     );
  //   } else if (
  //     RouteStore.lastPage !== "" &&
  //     window.location.pathname.includes("create") == true
  //   ) {
  //     return (
  //       <FontAwesomeIcon
  //         onClick={() => {
  //           window.history.back();
  //         }}
  //         icon={faChevronLeft}
  //         color="#4FD975"
  //         size={"4x"}
  //         style={{ margin: 5 }}
  //       />
  //     );
  //   }
  // };

  return (
    <div style={{ width: "100%", }}>
      <header
        id="header"
        className="header"
        style={{ position: "relative", width: "100%", top: 0 }}
      >
        <div className="content-container">
          <div className="header-content">
            {EditorStore.showTooltip == true ? <Tooltip /> : ""}

            <div className="left-section">
              {/* <Toolbar variant="dense"> */}
              {/* <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setOpen(true)}
                >
                  <MenuIcon />
                </IconButton> */}

              {
                // Show the logo if the user is logged out or if the browser is not in the specified resolution
                currentUser == null || !matches ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="logoIMG fr-logo"
                      style={{
                        backgroundImage: "url(/images/logo/logo.svg)",
                        backgroundSize: "contain",
                        margin: "auto",
                        width: "147px",
                        backgroundRepeat: "no-repeat",
                        cursor: "pointer",
                        height: "32.1px",
                        position: "relative",
                        left: "-1px",
                        marginTop: 0,
                      }}
                      onClick={() => {
                        window.location.href = "/";
                      }}
                    />
                    {currentUser == null ? (<div id="create-btn-mobile-wrap" style={{position:'absolute', left:154, marginTop:5}}>
                    <Link to={`/create`}><div id="create-no-circle"></div></Link>

                    </div>):""}
                  </div>
                ) : (
                  ""
                )
              }

              {/* </Toolbar> */}
              {/* <Drawer
                open={open}
                onClose={() => setOpen(false)}
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <Toolbar>
                  <IconButton edge="start" onClick={() => setOpen(false)}>
                    <ChevronLeftIcon />
                  </IconButton>
                </Toolbar>
                <Divider />
                <List>
                  {firebase.auth().currentUser && (
                    <ListItem key={"signout"}>
                      <Button
                        onClick={() => {
                          firebase
                            .auth()
                            .signOut()
                            .then(function () {
                              // Sign-out successful.
                              localStorage.clear();
                              window.location.replace("/");
                            })
                            .catch(function (error) {
                              // An error happened.
                            });
                        }}
                        size="small"
                      >
                        Sign Out
                      </Button>
                    </ListItem>
                  )}

                  <a href="http://about.flowroom.com/terms/" target="new_page">
                    <ListItem button key={"terms"}>
                      <ListItemText primary={"Terms"} />
                    </ListItem>
                  </a>

                  <a
                    href="http://about.flowroom.com/privacy-policy/"
                    target="new_page"
                  >
                    <ListItem button key={"privacy"}>
                      <ListItemText primary={"Privacy Policy"} />
                    </ListItem>
                  </a>

                  <a
                    href="http://about.flowroom.com/terms/#dmca"
                    target="new_page"
                  >
                    <ListItem button key={"dmca"}>
                      <ListItemText primary={"DMCA Policy"} />
                    </ListItem>
                  </a> */}

              {/* <a href="http://about.flowroom.com/" target="new_page">
                    <ListItem button key={"About"}>
                      <ListItemText primary={"About"} />
                    </ListItem>
                  </a> */}
              {/* </List> */}
              {/* </Drawer> */}
            </div>
            {/* {matches ? (
              <div className="middle-section">
                <Link to="/">
                  <img
                    src="/images/logo/logo.svg"
                    style={{ margin: "auto" }}
                    onClick={() => {
                      window.location.href = "/";
                    }}
                    className="logoIMG"
                  />
                </Link>
              </div>
            ) : (
              <></>
            )} */}
            <div class="header-icons">
              {/* <div
                style={{
                  color: "white",
                  position: "absolute",
                  height: 64,
                  right: 170,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: 0,
                  zIndex: 100,
                  cursor: "pointer",
                  marginRight: 20,
                }}
              >
                <Link to="/create">
                  <img src="../images/create.svg" style={{ marginRight: 23 }} />
                </Link>
                <img src="../images/create-info.svg" />
              </div> */}
                  {currentUser == null ? (<div id="create-btn-desktop-wrap" style={{position:'absolute', right:155, display:'flex'}}>
                  <Link to={`/create`}><div id="create-no-circle"></div></Link>
                    <div id="help-circle"></div>
                    </div>):""}

              {currentUser ? (
                <div className="right-section right-section-step">
                  {/* <FontAwesomeIcon
                  onClick={() => {
                    window.location.href = "/";
                  }}
                  icon={faHome}
                  color="#A962F1"
                  size={"4x"}
                  style={{
                    margin: 5,
                    visibility: "hidden",
                  }}
                /> */}
                  {/* <FontAwesomeIcon
                  className="header-create-icon"
                  onClick={() => {
                    window.location.href = "/create";
                  }}
                  icon={faPlus}
                  color="#4FD975"
                  size={"4x"}
                  style={{
                    margin: 5,
                    display: matches === true ? "block" : "none",
                  }}
                /> */}
                  <div
                    onClick={() => {
                      window.location.href = "/";
                    }}
                    id="infinity-icon"
                    style={{ cursor: "pointer" }}
                  ></div>

                  <div
                    style={{
                      display: "block",
                      position: "relative",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <Link to="/create">
                    {/* <div style={{backgroundImage:'url(/images/create-mobile-btn.svg', height:20, width:20, backgroundSize:'contain'}}></div> */}
                    <img src="/images/desktop-header-icons/create.svg" style={{height:19, width:19}}/>

                      {/* <img src="../images/header-create-no-circle.svg" /> */}
                      {currentUser == null ? (<div id="create-no-circle"></div>):""} 
                      {/* <Button
                      startIcon={<AddIcon />}
                      variant="outlined"
                      color="primary"
                      // className={classes.button}
                    >
                      Create
                    </Button> */}
                      {/* <div
                      className="green-button create-button-header create-button-step"
                      style={{
                        // background: "#4fd975",
                        borderRadius: "20px",
                        padding: "2px 0px",
                        alignItems: "center",
                        width: "105px",
                        justifyContent: "center",

                        marginLeft: 7,
                      }}
                    >
                      <AddIcon
                        style={{
                          fontSize: 17,
                          color: "#3F3F3E",
                          fontWeight: "bolder",
                        }}
                      />
                      <p
                        style={{
                          fontSize: 20,
                          color: "#3F3F3E",
                          paddingRight: 5,
                          fontFamily: "quicksand",
                        }}
                      >
                        Create
                      </p>
                    </div> */}
                    </Link>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      left: 0,
                      top: 0,
                      cursor: "pointer",
                    }}
                  >
                    <div
                      onClick={() => {
                        // let currentUser = firebase.auth().currentUser;
                        if (currentUser == null) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }
                        if (
                          currentUser.displayName != null &&
                          currentUser.displayName != ""
                        ) {
                          window.location.href = "/messages";
                        } else {
                          AuthenticationStore.setShowModal(true);
                        }
                      }}
                    >
                      <img src="/images/desktop-header-icons/messages.svg" style={{height:19, width:19}}/>

                      {/* <img src="../images/header-mail.svg" /> */}
                      {/* <FontAwesomeIcon
                      className="header-help-icon"
                      icon={faQuestionCircle}
                      color="#A962F1"
                      // size={}
                      style={{
                        display:
                          matches === true ||
                          AuthenticationStore.loggedIn === true
                            ? "block"
                            : "none",
                        margin: 10,
                        cursor: "pointer",
                      }}
                    /> */}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      left: 0,
                      top: 0,
                      cursor: "pointer",
                    }}
                  >
                    <Link to="/help">
                      <div className="question-mobile help-step">
                        {/* <img src="../images/header-info.svg" /> */}
                        {/* <div id="header-info"></div> */}
                        <img src="/images/desktop-header-icons/help.svg" style={{height:19, width:19}}/>
                        {/* <FontAwesomeIcon
                      className="header-help-icon"
                      icon={faQuestionCircle}
                      color="#A962F1"
                      // size={}
                      style={{
                        display:
                          matches === true ||
                          AuthenticationStore.loggedIn === true
                            ? "block"
                            : "none",
                        margin: 10,
                        cursor: "pointer",
                      }}
                    /> */}
                      </div>
                    </Link>
                  </div>

                  <div
                    className="bell-mobile notifications-step"
                    onClick={() => {
                      // let currentUser = firebase.auth().currentUser;
                      if (currentUser == null) {
                        AuthenticationStore.setShowModal(true);
                        return;
                      }
                      if (
                        currentUser.displayName != null &&
                        currentUser.displayName != ""
                      ) {
                        window.location.href = "/notifications";
                      } else {
                        AuthenticationStore.setShowModal(true);
                      }
                    }}
                    style={{
                      position: "relative",
                      left: "0px",
                      top: "0px",
                      cursor:'pointer'
                    }}
                  >
                    {/* <div id="header-bell" style={{ cursor: "pointer" }}></div> */}
                    <div style={{position:'relative'}}>
                      {hasNewNotifications ? (<div style={{position:'absolute', backgroundColor:'#1c1c1c', height:7, width:7, borderRadius:'50%', left:11, top:0, zIndex:10000, display:'flex', alignItems:'center', justifyContent:'center'}}><div style={{backgroundColor:'#FF0000', height:5, width:5, borderRadius:'50%'}}></div></div>):""}
                    <img id="header-bell" src={hasNewNotifications ? '/images/desktop-header-icons/notification-bell.svg' : '/images/desktop-header-icons/notification-bell.svg'} style={{height:19, width:19, opacity:0.7}} />
                    </div>

                    {/* <FontAwesomeIcon
                    className="header-notification-icon"
                    icon={faBellExclamation}
                    color="#F06263"
                    size="4x"
                    style={{
                      margin: 10,
                      cursor: "pointer",
                      display:
                        matches === true ||
                        AuthenticationStore.loggedIn === true
                          ? "block"
                          : "none",
                    }}
                  /> */}

                    {/* <div
                    className="notification-bell-dot"
                    style={{
                      height: 12,
                      width: 12,
                      backgroundColor: "#6371F6",
                      borderRadius: "50%",
                      position: "absolute",
                      color: "white",
                      fontFamily: "quicksand",
                      justifyContent: "center",
                      alignItems: "center",
                      display:
                        AuthenticationStore.notificationNumber !== 0
                          ? "flex"
                          : "none",
                      top: 10,

                      border: "2px solid #3f3f3e",
                      fontWeight: "bold",
                    }}
                  ></div> */}
                  </div>
                  <div
                    onClick={() => {
                      WidgetStore.setShowPaymentModal(true);
                    }}
                    style={{
                      height: 23,
                      maxWidth: 120,
                      backgroundColor: "#4FD975",
                      borderRadius: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingLeft: 7,
                      paddingRight: 10,
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src="/images/credit-star.svg"
                      style={{ height: 12, marginRight: 5 }}
                    />
                    <p
                      style={{
                        fontFamily: "quicksand",
                        fontSize: 12,
                        color: "#1C1C1C",
                      }}
                    >
                      Credits: {formatNumber(FlowStore.credit)}
                    </p>
                  </div>
                  <div
                    className="profile-avatar-wrap your-profile-step"
                    style={{
                      display: currentUser !== null ? "block" : "none",
                      borderRadius: 20,
                      height: "35px",
                      width: "35px",
                      border: "0px solid #4FD975",
                      borderRadius: "20px",
                      overflow: "hidden",
                      borderRadius: 50,
                      margin: 0,
                      position: "relative",
                      left: "0px",
                    }}
                    onClick={() => {
                      // let currentUser = firebase.auth().currentUser;
                      if (showDropDown === false) {
                        setShowDropDown(true);
                        document.getElementById("profile-wrap").style.display =
                          "block";
                      } else {
                        document.getElementById("profile-wrap").style.display =
                          "none";
                        setShowDropDown(false);
                      }
                      if (currentUser == null) {
                        AuthenticationStore.setShowModal(true);
                        return;
                      }
                      if (
                        currentUser.displayName != null &&
                        currentUser.displayName != ""
                      ) {
                      } else {
                        AuthenticationStore.setShowModal(true);
                      }
                    }}
                  >
                    <div
                      id="profile-wrap"
                      style={{
                        border: "2px solid rgb(79, 217, 117)",
                        position: "absolute",
                        background: "transparent",
                        top: 0,
                        zIndex: 100,
                        display: showDropDown === false ? "none" : "block",
                      }}
                    ></div>
                    <ReactImageFallback
                      className="header-profile-icon-desktop"
                      src={
                        currentUser
                          ? currentUser.photoURL
                          : currentUser.photoURL === null ||
                            currentUser.photoURL == undefined
                          ? "/images/Flowroom_Default_Avatar.png"
                          : currentUser.photoURL || currentUser.photoURL
                      }
                      style={{ size: 35 }}
                      fallbackImage="/images/Flowroom_Default_Avatar.png"
                      initialImage="/images/Flowroom_Default_Avatar.png"
                    />
                  </div>

                  <div
                    className="profile-dropdown"
                    style={{
                      backgroundColor: "#3F3F3E",
                      position: "absolute",
                      zIndex: 10000000,
                      top: "70px",
                      right: "14px",
                      borderTopRightRadius: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      display: showDropDown === true ? "flex" : "none",
                      boxSizing: "border-box",

                      /* Auto layout */
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      padding: 20,
                      gap: 28,
                      position: "absolute",
                      width: "170px",
                      height: "220px",
                      top: "60px",
                      background: "#323232",
                      border: "1px solid #4FD975",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "auto",
                        justifyContent: "space-between",
                        height: "173px",
                        width: 140,
                      }}
                    >
                      <div
                        className="view-profile-step"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          width: "122px",
                        }}
                        onClick={() => {
                          // let currentUser = firebase.auth().currentUser;
                          if (currentUser == null) {
                            AuthenticationStore.setShowModal(true);
                            return;
                          }
                          if (
                            currentUser.displayName != null &&
                            currentUser.displayName != ""
                          ) {
                            window.location.href = `/${currentUser.displayName}`;
                          } else {
                            AuthenticationStore.setShowModal(true);
                          }
                        }}
                      >
                        {/* <FontAwesomeIcon
                          icon={faUser}
                          color="#6371F6"
                          size="4x"
                          style={{ position: "relative", right: 7 }}
                        /> */}
                        <img src="/images/user-octagon.svg" />
                        <p
                          style={{
                            color: "white",
                            fontSize: 22,
                            position: "relative",
                            left: 7,
                            fontFamily: "Quicksand",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                          className="noselect"
                        >
                          View Profile
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          window.location.replace("/settings");
                        }}
                        className="settings-step"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100px",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          left: 0,
                          position: "relative",
                        }}
                      >
                        {/* <FontAwesomeIcon
                          style={{ position: "relative", right: 10 }}
                          icon={faCog}
                          color="#4FD975"
                          size="4x"
                        /> */}
                        <img src="/images/setting.svg" />
                        <p
                          style={{
                            color: "white",
                            fontFamily: "Quicksand",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                          className="noselect"
                        >
                          Settings
                        </p>
                      </div>

                      <div
                        onClick={() => {
                          WidgetStore.setShowPaymentModal(true);
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "95px",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          fontFamily: "Quicksand",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        {/* <FontAwesomeIcon
                          icon={faDollarSign}
                          color="#4FD975"
                          size="4x"
                          style={{ position: "relative", right: 7 }}
                        /> */}
                        <img src="/images/getpro.svg" />
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              fontFamily: "Quicksand",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: 16,
                              color: "white",
                            }}
                          >
                            Get Pro
                          </p>
                        </div>
                      </div>
                      <Link to={`/CreateInvite`}>
                      <div
                        
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "114px",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          fontFamily: "Quicksand",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        {/* <FontAwesomeIcon
                          icon={faDollarSign}
                          color="#4FD975"
                          size="4x"
                          style={{ position: "relative", right: 7 }}
                        /> */}
                        <FontAwesomeIcon
          onClick={() => {
          }}
          icon={faUserPlus}
          color="#fff"
          style={{ margin: 0, fontSize:20 }}
        />
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              fontFamily: "Quicksand",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: 16,
                              color: "white",
                            }}
                          >
                            Invite Link
                          </p>
                        </div>
                      </div>
                      </Link>

                      {toggleWalkthrough === false ? (
                        <div
                          onClick={() => {
                            setToggleWalkthrough(true);
                          }}
                          style={{
                            display: "none",
                            alignItems: "center",
                            width: "130px",
                            justifyContent: "space-around",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="#4FD975"
                            size="4x"
                            style={{ position: "relative", left: 20 }}
                          />
                          <p
                            style={{
                              fontFamily: "quicksand",
                              color: "#4FD975",
                              fontSize: 20,
                              position: "relative",
                              left: 33,
                            }}
                            className="noselect"
                          >
                            Walkthroughs
                          </p>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setToggleWalkthrough(false);
                          }}
                          style={{
                            display: "none",
                            alignItems: "center",
                            width: "130px",
                            justifyContent: "space-around",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="rgb(240, 98, 99)"
                            size="4x"
                            style={{ position: "relative", left: 20 }}
                          />
                          <p
                            style={{
                              fontFamily: "quicksand",
                              color: "rgb(240, 98, 99)",
                              fontSize: 20,
                              position: "relative",
                              left: 33,
                            }}
                            className="noselect"
                          >
                            Walkthroughs
                          </p>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100.7px",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          firebase
                            .auth()
                            .signOut()
                            .then(function () {
                              // Sign-out successful.
                              localStorage.clear();
                              localStorage.setItem(
                                "flowroom-user",
                                JSON.stringify({
                                  isLoggedIn: false,
                                })
                              );
                              AuthenticationStore.setIsLoggedIn(false);

                              window.location.replace("/");
                            })
                            .catch(function (error) {
                              // An error happened.
                            });
                        }}
                      >
                        <img src="/images/logout.svg" />
                        <p
                          style={{
                            color: "#FFF",
                            fontFamily: "Quicksand",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                          className="noselect"
                        >
                          Sign Out
                        </p>
                      </div>
                    </div>
                    {/* <div
                      className="credits-step"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 40,
                        width: "100%",
                        justifyContent: "space-around",
                        backgroundColor: "#4FD975",
                        borderRadius: 25,
                        borderTopRightRadius: 0,
                      }}
                    >
                      <p
                        style={{
                          color: "#222",
                          fontFamily: "quicksand",
                          fontWeight: "bolder",
                        }}
                      >
                        {FlowStore.credit} Credits
                      </p>
                    </div> */}
                  </div>
                </div>
              ) : (
                <div
                  className="profile-loggedOutSignedIn-wrap"
                  style={{
                    display: currentUser == null ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoggedOutSignedIn
                    loginBtnStyle={currentUser == null ? loginBtnStyle : {}}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      {/* <ReactModal
        isOpen={AuthenticationStore.showModal}
        ariaHideApp={false}
        closeTimeoutMS={500}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#3F3F3E",
            overflow: "hidden",
            borderRadius: "15px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% / 1.1)",
            maxWidth: 570,
            // maxHeight: WidgetStore.currentModalHeight,
          },
        }}
      >
        <div
          onClick={() => {
            AuthenticationStore.setShowModal(false);
          }}
          style={{
            position: "absolute",
            right: 0,
            margin: 10,
            cursor: "pointer",
            zIndex: 1,
          }}
        >
          <img src="../images/close-circle-report.svg" />
        </div>
        <LoginM />
      </ReactModal> */}
      {/* <ReactModal
        isOpen={AuthenticationStore.showSignIn}
        ariaHideApp={false}
        closeTimeoutMS={500}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#3F3F3E",
            overflow: "hidden",
            borderRadius: "15px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% / 1.1)",
            maxWidth: 570,
            // maxHeight: WidgetStore.currentModalHeight,
          },
        }}
      >
        <div
          onClick={() => {
            AuthenticationStore.setShowSignIn(false);
          }}
          style={{
            position: "absolute",
            right: 0,
            margin: 10,
            cursor: "pointer",
            zIndex: 1,
          }}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            color="#F06263"
            size={"4x"}
            style={{
              display: "block",
              position: "absolute",
              right: 10,
              top: 10,
            }}
          />
        </div>
        <SignInM />
      </ReactModal> */}
      <style jsx>
        {`
        #profile-wrap {
          height:35px;
          width:35px;
        }
      

        /* New media query for mobile resolution */
        @media (max-width: 768px) {
          // #remix-desktop {
          //   display: none;
          // }
          #profile-wrap {
            height:24px;
            width:24px;
          }
      
        
        }
        
        `}
      </style>
    </div>
  );
});

export default React.memo(Header);
