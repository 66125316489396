import { CardHeader, List } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useRef } from "react";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { CommentsStoreContext } from "../../store/CommentsStore.js";
import CommentCard from "./NewCommentCard.tsx";
import NewCommentForm from "./NewCommentForm.tsx";

import { AuthContext } from "./AuthContext";


// const useStyles = makeStyles({
//   root: {
//     width: "calc(100% - 40px)",
//     backgroundColor: "#1C1C1C",
//     overflowY: "scroll",
//     height: "calc(100vh - 557px)",
//     margin: "auto",
//   },
//   fonts: {
//     fontWeight: "bold",
//     color: "lightgray",
//   },

//   username: {
//     display: "inline",
//     fontSize: 14,
//     color: "white",
//   },
//   comment: {
//     display: "inline",
//     fontSize: 15,
//     color: "white",
//   },
//   header: {
//     color: "white",
//   },
//   bullet: {
//     display: "inline-block",
//     margin: "0 2px",
//     transform: "scale(0.8)",
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
// });

const Comment = ({
  comments,
  flowId,
  myUserId,
}: {
  comments: any[];
  flowId: string;
  myUserId: string;
}) => {
  // const classes = useStyles();

  const messagesEndRef = useRef<any>(null);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const CommentsStore = useContext(CommentsStoreContext);
  const { currentUser } = useContext(AuthContext);

  const scrollToBottom = () => {
    if (messagesEndRef !== null) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      return;
    }
  };

  useEffect(() => {
    // scrollToBottom();
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  return (
    <>
      <div
        style={{
          height: "300px",
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom:'0px solid #3E3E3E'
        }}
      >
        <NewCommentForm scrollToBottom={scrollToBottom} flowId={flowId} />
      </div>
      <List
        // id="commentsScrollView"
        // className={`${classes.root} hidden-scrollbars`}
        key={flowId + "_list_" + Math.random()}
      >
        {comments.length > 0 ? (
          comments.map((comment) => {
            console.log("comment card", comment);
            return (
              <CommentCard
                key={comment.commentId}
                comment={comment.comment}
                commentId={comment.commentId}
                date={comment.date}
                flowId={comment.flowId}
                myUserId={myUserId}
                photoURL={comment.urlPhoto}
                isSelf={
                  comment.userId && comment.userId == currentUser.uid ? true : false
                }
                username={comment.username}
                userId={comment.userId}
                likesCount={comment.likesCount}
              />
            );
          })
        ) : (
          <CardHeader
            // className={classes.header}
            avatar={
              <div
                style={{
                  display: "flex",
                  backgroundImage: "url(./images/Flowroom_Default_Avatar.png)",
                  height: "55px",
                  width: "55px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                }}
              ></div>
            }
            title="No Comments"
            subheader="Be the first to comment"
          />
        )}
        <div ref={messagesEndRef} />
      </List>
    </>
  );
};

export default Comment;
