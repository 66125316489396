// libraries
// UI
import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import firebase from "firebase";
import Hashids from "hashids";
import moment from "moment";
import React, { useContext, useEffect, useState, useRef } from "react";
import { collection, Doc } from "typesaurus";
import { v4 as uuid } from "uuid";
// store
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";

import { CommentsStoreContext } from "../../store/CommentsStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import takeToken from "./bucket";
import { inspect } from "./db";
import { AuthContext } from "./AuthContext";
import { RoomContext } from "../Home/RoomContext.js"; // Make sure to import the correct context
import { useParams } from 'react-router-dom';
let globalPostCount = 0;
let globalPostResetTimeout = null;
interface IFlow {
  comments: number;
  date: string;
  description: string;
  featured: boolean;
  flowId: string;
  likes: number;
  thumbnail: string;
  title: string;
  urlCSS: string;
  urlHTML: string;
  urlJS: string;
  userId: string;
  username: string;
  views: number;
  visibility: string;
  weight: number;
}

interface IComment {
  comment: string;
  commentId: string;
  date: string;
  flowId: string;
  urlPhoto: string;
  userId: string;
  username: string;
}

interface IUser {
  bio: string;
  urlPhoto: string;
  userId: string;
  username: string;
  fullname: string;
}

let users = collection<IUser>("users");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      paddingRight: 10,
      paddingLeft: 15,
      // display: "grid",
      // alignItems: "center",
      // justifyContent: "center",
      // gridTemplateColumns: "3fr 1fr",
      // gridColumnGap: 16,
      // backgroundColor: "#3F3F3E",
      // "& > *": {
      //   margin: theme.spacing(1),
      //   width: "25ch",
      //   fontSize: "20px",
      // },
      // "& .MuiInput-underline:after": {
      //   borderBottomColor: "#40ffe8",
      // },
    },
  })
);
export default function NewCommentForm({ flowId, scrollToBottom }) {
  let classes = useStyles();
  let FlowStore = useContext(FlowStoreContext);
  let AuthenticationStore = useContext(AuthenticationStoreContext);
  let CommentsStore = useContext(CommentsStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  let [text, setText] = useState("");
  const maxChars = 300;
  let [userDocument, setUserDocument] = useState<Doc<IUser>>();
  const { currentUser } = useContext(AuthContext);
  const room = useContext(RoomContext);
  const [isCooldown, setIsCooldown] = useState(false);
  let { roomId, commentId, replyId } = useParams();
  const [lastSentTimes, setLastSentTimes] = useState([]);
const [isRateLimited, setIsRateLimited] = useState(false);
const [tooManyMessages, setTooManyMessages] = useState(false);
const [errorMsg, setErrorMsg] = useState(false);
const [postCount, setPostCount] = useState(0);
const [errorMessage, setErrorMessage] = useState('');


// Ref to keep track of the reset timeout
const postResetTimeoutRef = useRef(null);
// Ref to keep track of the post count and reset timeout
const postCountRef = useRef(0);
  console.log(roomId, commentId, replyId); // For debugging purposes
  const addLikeNotification = async (sender, receiver, commentContent, photoURL, newCommentId) => {
    const newNotification = {
      sender: sender,
      receiver: receiver,
      type: "commentLeft",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isSeen: false,
      flowId: flowId,
      commentId: newCommentId,
      additionalData: {
        commentContent: commentContent,
        photoURL: photoURL,
      },
    };
    await firebase.firestore().collection("notifications").add(newNotification);
  };

  const addMentionCommentNotification = async (sender, receiver, commentContent, photoURL, newCommentId) => {
    const newNotification = {
      sender: sender,
      receiver: receiver,
      type: "commentMention",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isSeen: false,
      flowId: flowId,
      commentId: newCommentId,
      additionalData: {
        commentContent: commentContent,
        photoURL: photoURL,
      },
    };
    await firebase.firestore().collection("notifications").add(newNotification);
  };
  

  const handlePost = async (e) => {
    e.preventDefault();
  
    // Rate limiting parameters
    const maxPosts = 5;
    const timeWindow = 10000; // 10 seconds cooldown
  
    // Handle rate limiting logic using global variables
    if (globalPostCount >= maxPosts) {
      if (!isRateLimited) {
        setIsRateLimited(true);
        console.log("You've posted too frequently. Please wait a few seconds before trying again.");
        console.log('globalPostCount (max exceeded)', globalPostCount);
  
        setTooManyMessages(true);
  
        // Clear any existing timeout and set a new one
        if (globalPostResetTimeout) {
          clearTimeout(globalPostResetTimeout);
        }
        globalPostResetTimeout = setTimeout(() => {
          setIsRateLimited(false);
          globalPostCount = 0;
          setErrorMessage('');
          setTooManyMessages(false);
        }, timeWindow);
      }
      return;
    }
  
    // Increment the global post count
    globalPostCount += 1;
    console.log('globalPostCount (incremented)', globalPostCount);
  
    // Ensure the text isn't empty and that the user isn't rate-limited
    if (!text || isRateLimited) {
      return;
    }
  
    // Check for user authentication
    if (currentUser === null || currentUser.displayName === null) {
      CommentsStore.setIsShowModal(false);
      AuthenticationStore.setShowModal(true);
      return;
    }
  
    // Normalize mentions to lowercase in the text
    let normalizedText = text.replace(/@\w+/g, mention => mention.toLowerCase());
  
    // Generate the new comment with relevant details
    let date = moment().format();
    let userId = currentUser?.uid;
    let username = currentUser.displayName;
    let newCommentId = new Hashids(uuid(), 10).encode(1, 2, 3);
    let comment = {
      comment: normalizedText,  // Use the normalized text
      commentId: newCommentId,
      date: date,
      flowId: flowId === "" ? EditorStore.flowId : room.flowId,
      urlPhoto: currentUser?.photoURL,
      userId: userId,
      username: username,
    };
  
    if(normalizedText.trim() === '') {
      setErrorMsg(true);
      return;
    }
  
    // Check for mentions in the normalized text and notify mentioned users
    const mentions = normalizedText.match(/@\w+/g);
  
    if (mentions) {
      mentions.forEach(mention => {
        let mentionedUser = mention.substring(1); // Already lowercase
        addMentionCommentNotification(username, mentionedUser, "", currentUser.photoURL, newCommentId);
      });
    }
  
    // Send like notification and update the CommentsStore
    addLikeNotification(username, room !== undefined ? room.username : EditorStore.username, "", currentUser.photoURL, newCommentId);
    await CommentsStore.setComment(comment);
    await CommentsStore.setIsAddComment(true);
    setText("");
  };
  
  
  
  

  return (
    <form className={classes.root} autoComplete="off" onSubmit={handlePost}>
      {currentUser !== null && currentUser.displayName !== null ? (
        <>
          <textarea
            className="formInputOutline comment-textarea"
            style={{
              width: "100%",
              border: 0,
              marginRight: 20,
              marginBottom: 10,
              paddingLeft: 20,
              color: "#FEFFFE",
              fontSize: 14,
              fontFamily: "quicksand",
              background: "#323232",
              borderRadius: 10,
              height: 143,
              paddingTop: 20,
              resize: "none",
            }}
            required
            value={text}
            placeholder="Leave a comment..."
            onChange={(e) => {
              if (e.target.value.length <= maxChars) {
                setText(e.target.value);
                setErrorMsg("");
              }
            }}
            id="standard-basic"
          />
              {tooManyMessages && (
          <div style={{ color: '#F06263', textAlign: 'center' }}>
            <p style={{ fontSize: 14, fontFamily: 'quicksand', wordBreak: 'break-all', marginBottom: 10 }}>Too many comment posts! Please wait...</p>
          </div>
        )}
              {errorMsg && (
          <div style={{ color: '#F06263', textAlign: 'center' }}>
            <p style={{ fontSize: 14, fontFamily: 'quicksand', wordBreak: 'break-all', marginBottom: 10 }}>You must provide a message!</p>
          </div>
        )}
          <div style={{ color: '#FEFFFE', fontSize: '1rem', textAlign: 'right', marginRight: 20, fontFamily:'quicksand' }}>
            {`${text.length} / ${maxChars}`}
          </div>
          <Button
            disabled={isCooldown}
            variant="outlined"
            type="submit"
            style={{
              width: "100%",
              border: "0px solid #40ffe8",
              marginLeft: 0,
              backgroundColor: "#A962F1",
              fontWeight: "bold",
              color: "#000",
              fontFamily: "QUICKSAND",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "11px 17px",
              gap: "10px",
              maxWidth: "960px",
              height: "50px",
              background: "#4FD975",
              borderRadius: 10,
              marginTop: 10,
            }}
          >
            Post
          </Button>
          {isCooldown && (
            <div style={{ color: '#FEFFFE', fontSize: '0.8rem', textAlign: 'center', marginTop: '10px' }}>
              Please wait a moment before posting again.
            </div>
          )}
        </>
      ) : (
        <>
          <TextField
            disabled
            value={text}
            id="standard-basic"
            label="Sign in to add a comment..."
            style={{ width: "100%", fontSize: 14 }}
          />
          <Button
            onClick={() => {
              CommentsStore.setIsShowModal(false);
              AuthenticationStore.setShowModal(true);
            }}
            variant="outlined"
            type="submit"
            style={{
              width: "100%",
              border: "0px solid #40ffe8",
              marginLeft: 0,
              backgroundColor: "#4FD975",
              borderRadius: "8px",
              fontWeight: "bold",
              color: "#000",
              fontFamily: "QUICKSAND",
              textTransform: "none",
              height: 40,
            }}
          >
            Post
          </Button>
        </>
      )}
    </form>
  );
}
